<template>
  <base-modal :isLoaded="isLoaded"
    :isError404="isError404"
    :scope="scope"
    :data="data"
    :error="error"
    @clearError="error = null">
      <template #modal-content>
        <div class="d-flex justify-content-between align-items-baseline mb-3">
          <h2 class="t-secondary t-xlr">{{ scope | capitalize | humanize }}</h2>
          <div class="d-flex">
            <share
              :scope="scope"
              :itemName="data.title"
              inModal
            />
            <bookmark-folder
              v-if="!isEmpty(data) && !isEmpty($currentUser)"
              :currentItem="data"
              :scope="getScope"
              isButton
            />
          </div>
        </div>
        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
        <h1 class="mb-3 t-700 t-xxlr mb-4" v-html="data.highlights.title"></h1>

        <p v-if="publicationDate" class="mb-0">
          <span class="t-500 t-md">Publication Date: </span>
          <span class="mr-4">{{ publicationDate }}</span>
          <span class="t-600">{{ publicationDateFromNow }}</span>
        </p>

        <p v-if="prNewswireSource" class="mb-0">
          <span class="t-500 t-md">Source: </span>
          <router-link v-if="!isEmpty(prNewswireSource.slug_values)"
            :to="{ name: 'organisation.show', params: { id: prNewswireSource.slug_values[0] }}"
          >{{ prNewswireSource.name }}</router-link>
          <span v-else>{{ prNewswireSource.name }}</span>
        </p>

        <p v-if="!isEmpty(data.organisations)" class="mb-0">
          <span class="t-500 t-md">Mentioned in this article: </span>
          <span v-for="(organisation, index) in data.organisations" :key="index">
            <router-link
              v-if="!isEmpty(organisation.slug_values)"
              :to="{ name: 'organisation.show', params: { id: organisation.slug_values[0] }}"
            >{{ organisation.name }}</router-link>
            <span v-else>{{ organisation.name }}</span>
            <span v-if="index < data.organisations.length - 1">, </span>
          </span>
        </p>

        <p class="mb-0" v-if="data.content_from.name">
          <span class="t-500 t-md">Content from: </span>
          <span>
            <a :href=data.content_from.url target="_blank">{{data.content_from.name}}</a></span>
        </p>

        <div v-if="!isEmpty(data.keywords)" class="my-4">
          <keywords
            :keywords="data.keywords"
            property="">
          </keywords>
        </div>

        <div v-if="!isEmpty(data.industries)" class="my-4">
          <keywords
            :keywords="data.industries"
            property="">
          </keywords>
        </div>

        <long-text-collapsible
          v-if="data.text"
          :title="isPRNewswire ? 'Article' : 'Teaser'"
          :truncateAt="-1"
          :isReadMore="false"
          :text="data.highlights.text"
          :withPreWrap="false"
          class="mb-4"
        />

        <button
          v-if="data.url && !isPRNewswire"
          class="btn btn-secondary t-600 mb-2 box-shadow"
          type="button"
          @click.prevent="clickViewFullArticle"
        >
          <i class="fa fa-newspaper-o t-xs" />
          <span class="px-2 t-600">View Full Article</span>
        </button>

      </template>

      <template #modal-sidebar>
        <h3 class="mb-2 t-600" v-if="data.url || !isEmpty(organisation_urls)">
          Links
        </h3>
        <a
          v-if="data.url"
          :href="data.url | formatWebsite"
          class="d-flex align-items-center"
          target="_blank">{{data.content_from.name}} Article <i class="ml-2 fa fa-external-link" /></a>

        <a
          v-for="(organisation_url, index) in organisation_urls"
          :key="`url-${index}`"
          :href="organisation_url | formatWebsite"
          class="d-flex align-items-center"
          target="_blank">{{ organisation_url }} <i class="ml-2 fa fa-external-link" /></a>

        <create-alert
          v-for="(organisation, index) in organisationsWithSlugValues"
          :key="`alert-${index}`"
          :item="organisation"
          :scope="'organisations'"
          :customTooltip="`Get an email when news or research <br />from ${organisation.name} is added`"
          :customText="`Create Alert for ${organisation.name}`"
          isSecondary
          class="mt-4 t-overflow"
        />

        <identifiers :item="data" />
      </template>
  </base-modal>
</template>

<script>
import NewsService from '@/services/news'
import sharedJsModal from '@/mixins/search/results/baseModal'

import BaseModal from '@/components/search/results/BaseModal'
import Keywords from '@/components/shared/Keywords'
import BookmarkFolder from '@/components/bookmark_folder/Show'
import LongTextCollapsible from '@/components/shared/LongTextCollapsible'
import Identifiers from '@/components/qa/Identifiers'
import Share from '@/components/shared/profile/Share'
import CreateAlert from '@/components/shared/profile/CreateAlert'

export default {
  name: 'modalNews',
  mixins: [sharedJsModal],
  components: {
    BaseModal,
    Keywords,
    BookmarkFolder,
    LongTextCollapsible,
    Identifiers,
    Share,
    CreateAlert
  },
  props: {
    slug: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      objectForHighlighting: {
        properties: ['text', 'title']
      }
    }
  },
  created () {
    this.scope = 'news'
  },
  computed: {
    publicationDate () {
      if (this.data.publication_date) {
        return this.$options.filters.formatDate(this.data.publication_date, 'MMMM D, YYYY')
      }
      return ''
    },
    publicationDateFromNow () {
      if (this.data.publication_date) {
        return this.$options.filters.fromNow(this.data.publication_date)
      }
      return ''
    },
    isPRNewswire () {
      return this.data.content_from.name === 'PR Newswire'
    },
    prNewswireSource () {
      if (this.isPRNewswire && !this.isEmpty(this.data.organisations)) {
        return this.data.organisations[0]
      }
      return null
    },
    processedOrganisations () {
      if (this.isEmpty(this.data.organisations)) {
        return { urls: [], withSlugValues: [] }
      }

      return this.data.organisations.reduce(
        (result, org) => {
          if (org.url) result.urls.push(org.url)
          if (org.slug_values) result.withSlugValues.push(org)
          return result
        },
        { urls: [], withSlugValues: [] }
      )
    },
    organisation_urls () {
      return this.processedOrganisations.urls
    },
    organisationsWithSlugValues () {
      return this.processedOrganisations.withSlugValues
    }
  },
  methods: {
    fetch (slug) {
      NewsService.getByID(slug).then(newsItem => {
        this.data = newsItem
        this.fetchHighlights(this.scope, slug)
      }).catch(err => {
        this.handleCustomError(err)
      })
    },
    clickViewFullArticle () {
      window.open(this.data.url, '_blank')
    }
  }
}
</script>
