<template>
  <div>
    <div class="d-flex flex-row">
      <h2 class="t-primary t-600 mb-1 t-xxlr">
        Analyze {{ category_name }} (AI)
        <i class="fa fa-info-circle t-secondary t-xs ml-2" v-tooltip="'Your prompts are private, anonymous and will not be used to train any AI models. Like all generative AI, our AI can provide inaccurate responses. We recommend verifying these responses before relying on them.'" />
      </h2>
    </div>

    <hr class="hr-small mb-4" />

    <p v-if="waitingState === WaitingState.LOADING_MODAL" class="text-center">
      <spinner :containerClass="`d-inline ml-4`" />
    </p>

    <template v-if="results.length > 0">
      <!-- Step 1. Choose analysis type-->
      <block-preview-collapsible
        :title="stepOneTitle"
        :previewHeight="0"
        :openCollapsed="remainingCredits > 0 ? false : true"
        class="pb-2"
        ref="stepAnalysisType">
        <callout v-if="answers" icon="fa fa-exclamation-triangle" class="bg-highlight-dark t-sm mt-2 pt-2 pb-2">
          Changing the analysis type will clear any existing analysis results.
        </callout>
        <p class="m-2 pt-2">
          <radio-button :name="`analysisType`"
            :value="AnalysisType.SINGLE"
            :valueText="typeDescriptionCopy(AnalysisType.SINGLE)"
            :isActive="selectedAnalysisType === AnalysisType.SINGLE"
            @input="handleRadioButton"
            class="mb-3"
          />

          <radio-button :name="`analysisType`"
            :value="AnalysisType.MULTI"
            :valueText="typeDescriptionCopy(AnalysisType.MULTI)"
            :isActive="selectedAnalysisType === AnalysisType.MULTI"
            @input="handleRadioButton"
            class="mb-3"
          />

          <template v-if="state.index==='patents'">
            <radio-button :name="`analysisType`"
            :value="AnalysisType.PATENT"
            :valueText="typeDescriptionCopy(AnalysisType.PATENT)"
            :isActive="selectedAnalysisType === AnalysisType.PATENT"
            @input="handleRadioButton"
          />
          </template>
        </p>
        <!-- Can be uncommented once the tutorial video url is available. -->
        <!-- <callout icon="fa fa-video-camera t-secondary" class="bg-transparent-blue t-sm t-sm mt-3 mb-2 pt-2 pb-2">
            New to AI Analysis? Watch our <a href="https://academiclabscom.notion.site/10b713804a64808cb7c3c08ab509aa57?v=10b713804a6480e29941000cebe1ed93">tutorial video</a>
          </callout> -->
      </block-preview-collapsible>

      <!-- Step 2. Select the results to analyze -->
      <block-preview-collapsible
        :title="`2. Select the ${ category_name } you want to analyze`"
        :previewHeight="0"
        :openCollapsed="true"
        class="pb-2"
        ref="stepSelectResults">
        <div class="m-2" v-if="selectedAnalysisType == AnalysisType.SINGLE || selectedAnalysisType == AnalysisType.PATENT">
          <span class="t-600 mr-4" :class="{'t-red': tooManyResultsSelected}">{{ countSelectedResults }} selected</span>
          <span v-if="tooManyResultsSelected">(You can select up to 4 Patents in this type of analysis.)</span>

          <template v-if="selectedAnalysisType == AnalysisType.SINGLE">
            <a href="#" @click.prevent="selectFirstX(0)" class="mr-4" :class="{
              't-secondary': countSelectedResults === 0
            }">Select none</a>
            <a href="#" v-if="remainingCredits > 0 && remainingCredits < 10" @click.prevent="selectFirstX(remainingCredits)" class="mr-4" :class="{
              't-secondary': lastClickedMultiSelect === remainingCredits
            }">Select first {{remainingCredits}}</a>
            <a href="#" v-if="remainingCredits >= 10" @click.prevent="selectFirstX(10)" class="mr-4" :class="{
              't-secondary': lastClickedMultiSelect === 10
            }">Select first 10</a>
            <a href="#" v-if="remainingCredits > 10 && remainingCredits < 25" @click.prevent="selectFirstX(remainingCredits)" class="mr-4" :class="{
              't-secondary': lastClickedMultiSelect === remainingCredits
            }">Select first {{remainingCredits}}</a>
            <a href="#" v-if="remainingCredits >= 25" @click.prevent="selectFirstX(25)" class="mr-4" :class="{
              't-secondary': lastClickedMultiSelect === 25
            }">Select first 25</a>
            <a href="#" v-if="remainingCredits > 25 && remainingCredits < 50" @click.prevent="selectFirstX(remainingCredits)" class="mr-4" :class="{
              't-secondary': lastClickedMultiSelect === remainingCredits
            }">Select first {{remainingCredits}}</a>
            <a href="#" v-if="remainingCredits >= 50" @click.prevent="selectFirstX(50)" :class="{
              't-secondary': lastClickedMultiSelect === 50
            }">Select first 50</a>
          </template>
          <ul class="border py-2 px-0 bd-radius alternating-li-bg-grey mt-2 mb-3" style="max-height: 250px; overflow-y: scroll;">
            <li v-for="(result, i) in results" :key="i" class="d-flex px-3 py-1">
              <span class="width--25px">{{ i + 1 }}</span>
              <checkbox
                class="flex-grow-1"
                :name="`select_value_${i}`"
                :text="result.name"
                :value="result.selected"
                @toggle="toggleSelected(i, result)"
              />
              <span class="text-nowrap"><router-link :to="generateLink(result)" target="_blank">open in new tab</router-link></span>
            </li>
          </ul>
        </div>
        <div class="mt-3" v-else-if="selectedAnalysisType == AnalysisType.MULTI">
          <range-slider
            ref="numofdocs"
            :label="`Number of ${ category_name } from your search results :`"
            @slider:change-value="val => numOfDocsSliderValue = val"
            @slider:mouse-up="sliderWasUsed = true"
            :width="200"
            :min="0"
            :max="countMaxItems"
            :step="rangeSliderStep"
            :value="this.numOfDocsSliderValue"
            class=""
          />
        </div>
        <div class="m-2" v-else>
          Please choose a type of analysis first.
        </div>
      </block-preview-collapsible>

      <!-- Step 3. Ask anything -->
      <block-preview-collapsible
        :title="'3. Ask anything'"
        :previewHeight="0"
        :openCollapsed="true"
        class="pb-2 mb-4"
        ref="stepAskAnything">
        <template v-if="countSelectedResults > 0">
          <!-- Textarea(s) for the user's question(s) -->
          <div v-for="(textarea, index) in questions" :key="index" class="pt-2 d-flex">
            <textarea
              class="form-control mb-2"
              placeholder="Ask anything..."
              v-validate="'min:3|max:2000'"
              data-vv-validate-on="blur"
              :class="{'is-invalid': errors.has('description')}"
              ref="questionAreas"
              v-model="questions[index]"
              :style="{ height: '100px' }"
              @input="onInput"
              >
            </textarea>
            <i v-if="index !== 0" @click="questions.splice(index, 1)" class="fa fa-close cursor-pointer px-2 is-hover--red"></i>
          </div>
          <!-- Row of buttons under the textareas -->
          <div class="d-flex mt-1">
            <link-button
              v-if="answers"
              icon="fa-refresh t-sm"
              class="t-sm mb-3"
              @click="analyzeResults"
              text=" "
            >
              <span :class="configurationModified ? '' : 't-regular'">
                {{ selectedAnalysisType === AnalysisType.MULTI ? 'Update preview' : 'Update answer' }}
              </span>
            </link-button>
            <link-button
              v-if="selectedAnalysisType===AnalysisType.MULTI && questions.length < 10"
              icon="fa-plus t-sm"
              class="t-sm mb-3"
              @click="addQuestion"
              text="Add another question"
            />
            <link-button
              icon="fa-external-link t-sm"
              class="t-sm mb-3"
              :isHyperlink="true"
              href='https://academiclabscom.notion.site/10b713804a64808cb7c3c08ab509aa57?v=10b713804a6480e29941000cebe1ed93'
              target="_blank"
              text="Example questions"
            />
          </div>
          <!-- The "Our AI knows about.." copy -->
          <callout icon="fa fa-info-circle t-secondary" class="bg-transparent-blue t-sm" v-if="activeDescription">
            <div v-html="activeDescription" />
          </callout>
        </template>
        <div class="m-2" v-else>
          Please make a selection of {{ category_name }} first.
        </div>
      </block-preview-collapsible>
    </template>

    <p v-if="waitingState === WaitingState.ANALYSING" class="text-center">
      <spinner :containerClass="`d-inline`" /><br />
    </p>

    <!-- Show analysis results -->
    <template v-if="answers">
      <template v-if="selectedAnalysisType == AnalysisType.SINGLE || selectedAnalysisType == AnalysisType.PATENT">
        <p class="t-xlr mb-3">Analysis results</p>
        <!-- Row of buttons above the results -->
        <div class="d-flex mt-2">
            <link-button
              icon="fa-download t-sm"
              class="t-sm mb-3"
              text="Download answer"
              @click="download"
            />
            <link-button
              icon="fa-clipboard t-sm"
              class="t-sm mb-3"
              text="Copy answer"
              v-clipboard="answers[0]"
            />
            <link-button
              icon="fa-pencil-square-o t-sm"
              class="t-sm mb-3"
              text="Edit question"
              @click="prepareToeditQuestions"
            />
          </div>
        <div style="height: auto; white-space: pre-line;" class="markdown mt-2 mb-4">
            <p v-html="`${ renderMarkDown(answers[0]) }`" />
          </div>
      </template>
      <template v-if="selectedAnalysisType == AnalysisType.MULTI">
        <p class="t-xlr mb-3">Analysis preview <span class="t-xxs t-secondary">(showing {{ answerPreviews.length }} {{ category_name }})</span></p>
        <div class="d-flex mt-2">
          <link-button
            icon="fa-download t-sm"
            class="t-sm mb-3"
            text=" "
            @click="download"
            v-tooltip="`Produces an Excel with the answers for all selected ${ category_name }`"
            :disabled="configurationModified"
          >
           Analyze all {{ countSelectedResults }} {{ category_name }}
          </link-button>
          <link-button
            icon="fa-pencil-square-o t-sm"
            class="t-sm mb-3"
            text="Edit question"
            @click="prepareToeditQuestions"
          />
        </div>
        <p v-if="waitingState === WaitingState.DOWNLOADING" class="text-center">
          <spinner :containerClass="`d-inline`" /><br />
          <span>
            Analysing {{ category_name }} ({{ downloadProgress }} / {{ countSelectedResults }})
          </span>
        </p>
        <div v-for="(x, i) in answerPreviews" :key="i">
          <block-preview-collapsible :title="`${(i+1)} - ${results[i].name}`" class="pb-2 mb-2">
            <p class="mt-2" v-html="`${ renderMarkDown(answerPreviews[i]) }`" />
          </block-preview-collapsible>
        </div>
      </template>
    </template>

    <!-- At the bottom of the modal: a sticky row of buttons + credit info -->
    <div class="d-flex flex-row justify-content-end align-items-center mt-4" style="position: sticky; bottom: -1.5rem;position: sticky; margin-bottom: -1.5rem; background-color:#fff; padding-bottom:1.5rem;padding-top: 0.5rem">
      <span class="align-left" v-tooltip="`1 credit per selected result (example: 1 use of AI analysis on a selection of 10 ${ category_name } consumes 10 credits)`">
        <span :class="{'t-red': remainingCredits <= 0}">{{ remainingCredits }} credits remaining</span> (<a href="https://www.academiclabs.com/contact-sales" target="_blank">buy more</a>)
      </span>
      <span class="flex-grow-1"><!-- spacer --></span>

      <!-- Close button; only ask for confirmation when appropriate -->
      <template v-if="safeToClose">
        <button
          class="btn bg-transparent no-border t-primary ml-3"
          type="button"
          @click="$events.$emit('modal:close')">Cancel
        </button>
      </template>
      <template v-else>
        <dropdown placement="bottom-end" noPadding ref="cancelDropdown">
          <template #button>
            <button
              class="btn bg-transparent no-border t-primary ml-3"
              type="button">Cancel
            </button>
          </template>
          <div class="dropdown-menu-custom box-shadow p-3">
            <div class="t-sm">Are you sure you'd like to close this dialog?<br /><span class="t-xs t-red">Any unsaved AI Analysis results will be lost.</span></div>
            <button
              class="btn btn-primary no-border ml-0 mt-2"
              type="button"
              @click="$events.$emit('modal:close')">Yes
            </button>
            <button
              class="btn bg-transparent no-border ml-3 mt-2"
              type="button"
              @click="this.$refs.cancelDropdown.closePopover()">No
            </button>
          </div>
        </dropdown>
      </template>

      <button v-if="answers && isSinglePromptAnalysis()"
        class="btn btn-primary px-4 ml-3"
        type="button"
        v-clipboard="answers[0]" @success="handleCopySuccess" @error="handleCopyError"
        v-html="copyCopy"
      />

      <button
        v-if="results && (!answers || configurationModified)"
        class="btn btn-primary px-4 ml-3"
        type="button"
        :disabled="analyzeButtonIsDisabled"
        @click="analyzeResults"
      >
        <template v-if="waitingState === WaitingState.ANALYSING"><span class="dots">Analyzing</span></template>
        <template v-else-if="!answers && selectedAnalysisType === AnalysisType.MULTI">Show preview</template>
        <template v-else-if="!answers">Analyze {{ countSelectedResults }} {{ 'result' | pluralize(countSelectedResults) }}</template>
        <template v-else-if="isSinglePromptAnalysis()">Update answer</template>
        <template v-else>Update preview</template>
      </button>

      <button v-if="answers && !configurationModified"
        class="btn btn-primary px-4 ml-3"
        type="button"
        :disabled="waitingState === WaitingState.DOWNLOADING"
        @click="download"
        v-html="downloadButtonCopy"
      />
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import SearchService from '@/services/search'
import Checkbox from '@/components/shared/form/Checkbox2'
import RadioButton from '@/components/shared/form/RadioButton'
import RangeSlider from '@/components/shared/form/RangeSlider.vue'
import LinkButton from '@/components/shared/form/LinkButton'
import BlockPreviewCollapsible from '@/components/shared/BlockPreviewCollapsible'
import Dropdown from '@/components/shared/form/Dropdown'
import Callout from '@/components/shared/Callout'

import moment from 'moment'
import showdown from 'showdown'

// Enum for all available analysis types
const AnalysisType = Object.freeze({
  SINGLE: 'single-prompt',
  MULTI: 'prompt-per-item',
  PATENT: 'patent-analysis'
})

// Enum with the different states of waiting the modal can be in
const WaitingState = Object.freeze({
  ACTIVE: 'active',
  LOADING_MODAL: 'loading_modal',
  ANALYSING: 'analysing',
  DOWNLOADING: 'downloading'
})

export default {
  name: 'aiAnalysisModal',
  components: {
    Spinner,
    Checkbox,
    RadioButton,
    LinkButton,
    RangeSlider,
    BlockPreviewCollapsible,
    Dropdown,
    Callout
  },
  props: {
    state: { // Current query, index, filters, etc.
      type: Object,
      required: true
    },
    count: { // Number of search results for current query
      type: Number,
      required: true
    }
  },
  data () {
    return {
      // Core data
      remainingCredits: 0,
      results: [], // The top X search results the user can choose from
      questions: [], // The user's questions
      defaultQuestions: {}, // Default questions to prefill the textarea
      shortenedQuestions: [], // The user's questions, boiled down to a few keywords
      selectedAnalysisType: '',
      descriptions: '', // User-friendly description(s) of "what our AI knows"
      activeDescription: '', // Which description is currently shown
      answerPreviews: [], // Document data previews
      answers: undefined, // LLM responses

      // Various UI-related variables
      category_name: '', // (custom) name of the data category
      modal_uuid: '', // Identifies this modal (only used for Pusher communication)
      waitingState: WaitingState.ACTIVE,
      copyCopy: 'Copy', // Label of the Copy-button
      numOfDocsSliderValue: 0, // Slider to select no. of search results
      lastClickedMultiSelect: 0,
      downloadProgress: 0,
      hasCopiedOrDownloadedAnswer: false, // To prevent the user from accidentally closing the modal
      sliderWasUsed: false, // Once slider is used, we can show the next step
      configurationModified: true, // So the user doesn't spend credits if re-analyzing an unchanged situation

      // Declare the "enums" here, so Vue can see them
      AnalysisType,
      WaitingState
    }
  },
  mounted () {
    // Set/override the category name
    this.category_name = this.getIndexAsString(this.state.index)
    if (this.category_name === 'Ongoing Research') {
      this.category_name = 'Ongoing Research Programs'
    } else if (this.category_name === 'News') {
      this.category_name = 'News Articles'
    }

    // Attach a UUID to identify this modal (only used when communicating with Pusher)
    this.modal_uuid = crypto.randomUUID()

    // Initialize remaining credits variable
    const credits = this.$currentUser.credits_remaining
    if (!credits || credits < 0) this.remainingCredits = 0
    else this.remainingCredits = credits

    this.initializeModal()

    // Install event handlers
    this.$events.$on('pusher:progress-update', (uuid, progress) => {
      // Only increase the progress counter if it's meant for this modal, and higher than before
      // (because Pusher cannot guarantee messages are received in order)
      const previewSize = this.answerPreviews.length
      if (this.modal_uuid === uuid && (progress + previewSize > this.downloadProgress)) {
        this.downloadProgress = progress + previewSize
      }
    })
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  computed: {
    countSelectedResults () {
      // Used to show the number in the Analyze button
      if (this.selectedAnalysisType === AnalysisType.MULTI) {
        return this.numOfDocsSliderValue
      } else {
        if (!this.results || this.results.length <= 0) return 0
        return this.results.reduce((count, currentResult) => {
          if (currentResult.selected) return count + 1
          return count
        }, 0)
      }
    },
    tooManyResultsSelected () {
      // It's only possible to select too many results in Patent analysis
      return (this.selectedAnalysisType === AnalysisType.PATENT && this.countSelectedResults > 4)
    },
    countMaxItems () {
      // Determines the max value of the range slider
      const itemLimit = 1000
      return Math.min(this.count, itemLimit, this.remainingCredits + this.answerPreviews.length)
    },
    tooManyCreditsInUse () {
      return this.countSelectedResults > this.remainingCredits
    },
    analyzeButtonIsDisabled () {
      return this.countSelectedResults === 0 ||
        this.waitingState === WaitingState.ANALYSING ||
        this.tooManyCreditsInUse ||
        this.tooManyResultsSelected ||
        this.questionsAreEmpty()
    },
    readyToShowQuestions () {
      // Only show the "Ask anything" step after the user has made a selection
      if (this.selectedAnalysisType === AnalysisType.MULTI && this.sliderWasUsed) {
        return true
      } else if (this.isSinglePromptAnalysis() && this.countSelectedResults > 0) {
        return true
      } else {
        return false
      }
    },
    downloadButtonCopy () {
      if (this.selectedAnalysisType === AnalysisType.MULTI && this.waitingState === WaitingState.DOWNLOADING) {
        return `<span class="dots mr-2">Download</span> (${this.downloadProgress}/${this.countSelectedResults})`
      } else {
        return 'Download'
      }
    },
    rangeSliderStep () {
      // Picks a step size that's just right .. not too finnicky, not too coarse
      if (this.countMaxItems <= 30) {
        return 1
      } else if (this.countMaxItems <= 300) {
        return 10
      } else {
        return 50
      }
    },
    safeToClose () {
      return (this.hasCopiedOrDownloadedAnswer || !this.answers) &&
      (this.waitingState === WaitingState.ACTIVE || this.waitingState === WaitingState.LOADING_MODAL)
    },
    stepOneTitle () {
      var title = '1. Choose your type of analysis'
      if (this.selectedAnalysisType === AnalysisType.SINGLE) {
        title += `  <span class="t-sm pl-1">[Set of ${this.category_name}]</span>`
      } else if (this.selectedAnalysisType === AnalysisType.MULTI) {
        title += `  <span class="t-sm pl-1">[Individual ${this.category_name}]</span>`
      } else if (this.selectedAnalysisType === AnalysisType.PATENT) {
        title += `  <span class="t-sm pl-1">[Patent details]</span>`
      }
      return title
    },
    singleTypeDescription () {
      const subtitle = this.category_name === 'patents' ? 'For summarizing, grouping or comparing research.' : 'bla'
      return `Ask anything about a set of ${this.category_name} (up to 50)<br /><span class='t-sm' style='color: rgba(var(--text-primary), 0.5)'>${subtitle}</span>`
    },
    multiTypeDescription () {
      return ''
    },
    analysisTypeDescription () {
      return ''
    }
  },
  watch: {
    selectedAnalysisType (newValue, oldValue) {
      if (newValue !== oldValue) {
        // If you're changing the type post-analysis, clear any answers to prevent an inconsistent UI
        this.answers = undefined
        this.answerPreviews = []
        this.configurationModified = true
        this.downloadProgress = 0
      }

      // Fill in the default question when needed
      if (!this.questions[0] || this.questions[0] === this.defaultQuestions[oldValue]) {
        this.questions[0] = this.defaultQuestions[newValue]
      }
      // If going from a multi-question type to a single-question type, merge all the questions into a single text box
      if (this.questions.length > 1 && this.isSinglePromptAnalysis()) {
        this.questions = [this.questions.join('\n')]
      }
      // Adapt the description text
      if (Object.keys(this.descriptions).length === 1) {
        this.activeDescription = Object.values(this.descriptions)[0]
      } else {
        this.activeDescription = this.descriptions[newValue]
      }
      // Proceed to the next step
      if (oldValue === '') {
        this.$refs.stepAnalysisType.collapse()
      }
      this.$refs.stepSelectResults.calculateContentHeight()
      this.$refs.stepSelectResults.expand()
    },
    readyToShowQuestions (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$refs.stepAnalysisType.collapse()
        this.prepareToeditQuestions()
      }
    },
    countSelectedResults (newValue, oldValue) {
      if (oldValue !== newValue && this.isSinglePromptAnalysis()) {
        this.configurationModified = true
      }
      if (oldValue !== newValue && this.selectedAnalysisType === AnalysisType.MULTI) {
        // The preview answers will stay the same when countSelectedResults changes
        // .. so in that sense the configuration hasn't changed, unless you want fewer than the 5 previewed results.
        if (newValue < 5) {
          this.configurationModified = true
        }

        // We will reset downloadProgress though, to allow a new download
        this.downloadProgress = 0
      }
      // When going from 0 to >0 (or vice versa), the contents of step 3 changes; recompute its height
      if (oldValue !== newValue && (oldValue === 0 || newValue === 0) && this.$refs.stepAskAnything) {
        this.$refs.stepAskAnything.calculateContentHeight()
      }
    }

  },
  methods: {
    handleRadioButton (checked, value) {
      this.selectedAnalysisType = value
    },
    generateLink (result) {
      // Produces links for the top X search results list
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          [this.singularizeIndex(this.state.index)]: result.slug_values[0],
          hr: this.$options.filters.slugify(result.name)
        }
      }
    },
    selectFirstX (amount) {
      this.lastClickedMultiSelect = amount
      this.results.forEach((result, index) => {
        if (index < amount) this.$set(this.results, index, { ...result, selected: true })
        else this.$set(this.results, index, { ...result, selected: false })
      })
    },
    toggleSelected (index, result) {
      // Reset "select first x results" thingie
      this.lastClickedMultiSelect = -1
      this.$set(this.results, index, {
        ...result,
        selected: !result.selected
      })
    },
    setremainingCredits (credits) {
      if (!credits || credits < 0) this.remainingCredits = 0
      else this.remainingCredits = credits

      this.$auth.updateCurrentUser({
        ...this.$currentUser,
        credits_remaining: this.remainingCredits
      })
    },
    initializeModal () {
      // Retrieves the top X search results, the default questions & description text
      this.waitingState = WaitingState.LOADING_MODAL

      SearchService.getAiAnalysisOverview({ ...this.state }).then(response => {
        this.results = response.items.map(item => {
          return {
            ...item,
            selected: false
          }
        })

        this.defaultQuestions = response.default_questions
        this.descriptions = response.descriptions

        this.waitingState = WaitingState.ACTIVE
      }).catch(err => {
        this.handleAnalyzeError(err)
      })
    },
    addQuestion () {
      this.questions.push('')
      this.$refs.stepAskAnything.calculateContentHeight()
    },
    analyzeResults () {
      if (!this.configurationModified) {
        // Don't spend credits if nothing was changed!
        return
      }

      // Ditch any empty text boxes (but ensure you at least have 1 text box)
      this.questions = this.questions.filter(str => str.trim() !== '')
      if (this.questions.length === 0) {
        this.questions = ['']
      }

      // Make room for the analysis results
      this.$refs.stepAnalysisType.collapse()
      this.$refs.stepSelectResults.collapse()
      this.$refs.stepAskAnything.collapse()

      this.waitingState = WaitingState.ANALYSING

      // Single-prompt analysis
      if (this.isSinglePromptAnalysis()) {
        const selectedItems = this.results
          .filter(r => r.selected)
          .map((result, i) => {
            return {
              order: i,
              slug_values: result.slug_values
            }
          })
        SearchService.getAiAnalysis({
          index: this.state.index,
          analysisType: this.selectedAnalysisType,
          question: this.questions[0],
          items: selectedItems
        }).then(response => {
          this.setremainingCredits(response.credits_remaining)
          this.answers = []
          this.answers.push(response.data)

          this.waitingState = WaitingState.ACTIVE
          this.configurationModified = false
        }).catch(err => {
          this.handleAnalyzeError(err)
        })
      // Multi-prompt analysis; only need to produce a preview
      } else if (this.selectedAnalysisType === AnalysisType.MULTI) {
        SearchService.getAiAnalysisPromptPerItemPreview({
          ...this.state,
          index: this.state.index,
          questions: this.questions,
          numItems: this.numOfDocsSliderValue,
          url: this.$route.fullPath,
          uuid: this.modal_uuid
        }).then(response => {
          this.shortenedQuestions = response.shortened_questions
          this.setremainingCredits(response.credits_remaining)
          this.waitingState = WaitingState.ACTIVE
          this.downloadProgress = 0
          this.configurationModified = false
          this.answers = response.preview_answers
          this.answerPreviews = []
          for (let i = 0; i < this.answers.length; i++) {
            var preview = ''
            for (const [j, answer] of this.answers[i].entries()) {
              preview += `**${response.shortened_questions[j]}**: `
              preview += `${answer}\n`
            }
            preview += `\n---\n\n${response.preview_data[i]}`
            this.answerPreviews.push(preview)
          }
        }).catch(err => {
          this.handleAnalyzeError(err)
        })
      }
    },
    renderMarkDown (mdText) {
      return new showdown.Converter({
        disableForced4SpacesIndentedSublists: true, // Because Chat uses fewer spaces to indent lists
        simpleLineBreaks: true, // Turns all \n into <br />
        simplifiedAutoLink: true, // Make urls clickable
        openLinksInNewWindow: true, // Make all links open in a new tab
        tables: true // Render Markdown tables
      }).makeHtml(mdText)
    },
    handleCopySuccess () {
      this.hasCopiedOrDownloadedAnswer = true
      this.copyCopy = 'Copied!'
      setTimeout(this.resetCopy, 2500)
    },
    handleCopyError () {
      this.copyCopy = 'Could not copy.'
      setTimeout(this.resetCopy, 2500)
    },
    onInput () {
      this.configurationModified = true
    },
    questionsAreEmpty () {
      return this.questions.every(str => str.trim() === '')
    },
    typeDescriptionCopy (analysisType) {
      var description = ''
      var subtitle = ''
      if (analysisType === AnalysisType.SINGLE) {
        description = `Ask anything about a set of ${this.category_name} (up to 50)`
        if (this.category_name === 'Patents') {
          subtitle = 'For summarizing, grouping, or comparing patent abstracts.'
        } else {
          subtitle = 'For summarizing, grouping or comparing research.'
        }
      } else if (analysisType === AnalysisType.MULTI) {
        description = `Extract insights from individual ${this.category_name} (up to 1000)`
        if (this.category_name === 'Patents') {
          subtitle = 'For extracting insights and creating custom filters from patent abstracts and claims.<br />Answers to each question are provided in an Excel export file.'
        } else {
          subtitle = 'For extracting insights and creating custom filters. Answers to each question are provided in an Excel export file.'
        }
      } else if (analysisType === AnalysisType.PATENT) {
        description = 'Analyze Patents in detail (up to 4)'
        // subtitle = 'When patent claims and full-text need to be taken into account, for detailed patent analysis and comparison.'
        subtitle = 'To quickly grasp the meaning of patent claims, perform deep analyses, and compare key insights across up to four complete patents (abstracts, claims, and full-texts).'
      }

      return `${description}<br /><span class='t-sm' style='color: rgba(var(--text-primary), 0.5)'>${subtitle}</span>`
    },
    isSinglePromptAnalysis () {
      return this.selectedAnalysisType === AnalysisType.SINGLE || this.selectedAnalysisType === AnalysisType.PATENT
    },
    handleAnalyzeError (err) {
      var errorMsg = ''
      if (err.status === 401) {
        this.setremainingCredits(0)
        errorMsg = 'Not authorized to perform this operation.'
      } else if (err.status === 400) {
        if (err.data && err.data.error) {
          errorMsg = err.data.error
        } else {
          errorMsg = 'Oops, our AI has stumbled! An unexpected error has occured. Please try again.'
        }
      } else if (err.status === 200) {
        errorMsg = err.data.message
      } else {
        errorMsg = 'Oops, something unexpected has happened. Please try again later.'
      }
      this.$flash({ message: this.renderError(errorMsg) }, 'danger')
      this.waitingState = WaitingState.ACTIVE
    },
    resetCopy () {
      this.copyCopy = 'Copy'
    },
    prepareToeditQuestions () {
      this.$refs.stepAskAnything.calculateContentHeight()
      this.$refs.stepAskAnything.expand()

      // Focus + select the default question-text, so you can easily replace it by just typing
      setTimeout(() => {
        const questionArea = this.$refs.questionAreas[0]
        questionArea.select()
        // select() will also scroll the textarea to the bottom, which we don't want
        questionArea.scrollTop = 0
      }, 0)
    },
    download () {
      if (this.selectedAnalysisType === AnalysisType.MULTI) {
        if (!this.configurationModified && this.downloadProgress > 0) {
          this.$flash({ message: 'You\'ve already downloaded this exact file. Feel free to try again after changing your questions or selection.' }, 'warning')
          return
        }

        this.downloadProgress = 0
        // Analyse all remaining results
        this.waitingState = WaitingState.DOWNLOADING
        SearchService.getAiAnalysisPromptPerItem({
          ...this.state,
          index: this.state.index,
          questions: this.questions,
          answerPreviews: this.answers,
          numItems: this.numOfDocsSliderValue,
          shortenedQuestions: this.shortenedQuestions,
          url: this.$route.fullPath,
          uuid: this.modal_uuid
        }).then(response => {
          this.setremainingCredits(response.headers.get('credits_remaining'))
          this.waitingState = WaitingState.ACTIVE
          this.configurationModified = false
          this.downloadHelper(response.data, 'xlsx')
        }).catch(err => {
          this.handleAnalyzeError(err)
        })
      } else {
        this.downloadHelper(this.answers[0], 'txt')
      }
    },
    downloadHelper (fileData, fileExtension) {
      this.hasCopiedOrDownloadedAnswer = true

      const fileName = `${this.getIndexAsString(this.state.index)}-${moment().format('YYYY-MM-DD')}.${fileExtension}`
      // Generate blob from response and virtual link that will be 'clicked' to download the export-file
      const url = window.URL.createObjectURL(new Blob([fileData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      link.click()
    },
    handleBeforeUnload (event) {
      // If needed, ask for confirmation if the user tries to close the browser tab
      if (this.safeToClose) {
        return
      }

      event.preventDefault() // Legacy method for older browsers
      event.returnValue = '' // Standard method for modern browsers
      return '' // Some browsers require this
    }
  }
}
</script>
