<template>
  <div class="radiobutton my-0">
    <input :name="name" :id="value" type="radio" :value="value" @change="trigger" :checked="isActive" :disabled="isDisabled">
    <label :for="value" class="radiobutton_label clearfix label-emphasis" :class="{'is-disabled': isDisabled }"></label>

    <label :for="value" class="radiobutton_content clearfix" :class="{'is-disabled': isDisabled }">
      <i  v-if="icon" class="radiobutton_icon t-secondary" :class="[{'radiobutton_icon-checked': isChecked}, icon]"></i>
      <span class="radiobutton_name" :class="{'t-600' : description}" v-html="getText" />
    </label>

    <p class="note" v-if="description" v-html="description"></p>
  </div>
</template>

<script>
export default {
  name: 'formRadioButton',
  props: {
    value: {
      type: String,
      default: ''
    },
    valueText: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: 0
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isChecked: false
    }
  },
  mounted () {
    this.isChecked = this.isActive
  },
  watch: {
    value (newVal) {
      this.isChecked = this.isActive
    }
  },
  computed: {
    getText () {
      return this.valueText ? this.valueText : this.$options.filters.capitalize(this.value)
    }
  },
  methods: {
    trigger (e) {
      this.$emit('input', e.target.checked, this.value, this.id)
    }
  }
}
</script>
