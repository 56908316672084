<template>
  <section>
    <h2 class="mb-1 t-600 d-flex justify-content-between flex-wrap">
      <span>Results per {{ scope | humanize | singularize }}</span>
      <span class="t-400 t-xxs pt-1">
        Select {{ humanReadableScope }} to apply filter and close window to see filtered results.
      </span>
    </h2>

    <!-- render error messages -->
    <static-messages v-if="error && isLoaded" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- help text -->
    <p class="t-500 mb-4">
      You've selected category <span class="t-blue t-700">{{ getIndexAsString(state.index) }}</span> with keyword
      <span v-if="state.keyword" class="t-blue t-700">{{ state.keyword }}</span>
      <span v-else class="t-400 t-italic">(no keyword selected)</span>
    </p>

    <!-- render results -->
    <div v-if="!error && isLoaded">
      <div class="row">
        <div class="form-group mb-1 col-md-4">
          <label class="select-label--small">
            <select class="form-control" v-model="sort">
              <option value="alpha" class="t-sm">Sort results alphabetically</option>
              <option value="results" class="t-sm">Sort on total results</option>
            </select>
          </label>
        </div>

        <div v-if="hasFilters" class="form-group mb-1 col-md-4">
          <label class="select-label--small">
            <select class="form-control" v-model="filter">
              <option value="all" class="t-sm">Show all organisations</option>
              <option value="academic" class="t-sm">Show only academic organisations</option>
              <option value="other" class="t-sm">Show only non-academic organisations</option>
            </select>
          </label>
        </div>

        <div class="col-lg-4 col-md-12 pt-1">
          <checkbox-shared
            v-model="isOnlyWithNullValues"
            :text="`Only show ${humanReadableScope} with results`"
            :name="'hide_null_values'"
            @input="toggleWithOrWithoutResults">
          </checkbox-shared>
        </div>
      </div>

      <hr class="my-4" />

      <div class="row">
        <div v-for="(c, i) in columns" :key="i" class="col-md-6 col-xl-4">
          <p class="text-right t-sm t-secondary mb-1 mr-3 d-md-block"
            :class="{ 'd-none': i > 0 }"
          >
            <span class="t-600 t-regular">search results</span> / total
          </p>
          <div v-for="(category, cI) in c" :key="cI">
            <div
              @click.prevent="toggleCollapse(category)"
              class="mb-2 px-3 py-2 bg-filter-grey is-hover bd-radius d-flex justify-content-between"
            >
              <span class="d-flex align-items-center pr-2">
                <i class="fa width--15px"
                  :class="{'fa-angle-down': !category.collapsed, 'fa-angle-right': category.collapsed}"
                />
                <template v-if="showCheckAllBox">
                  <label @click.stop class="indeterminate-checkbox__wrapper mb-0 is-hover">
                    <input
                      type="checkbox"
                      :ref="`checkall_${category.name}`"
                      @change="checkAll($event, category)"
                    />
                    <div class="indeterminate-checkbox__checkbox mr-1" />
                  </label>
                </template>
                <span class="text-nowrap t-600">{{ category.name | humanize(' ', '-') }}</span>
              </span>
              <template v-if="hasFilters">
                <span class="t-secondary t-sm">
                  <span class="t-regular t-500">
                    {{ filter !== 'all' ? category.values[filter].count : category.count }}
                  </span> /
                  {{ filter !== 'all' ? category.values[filter].total : category.total }}
                </span>
              </template>
              <template v-else>
                <span class="t-secondary t-sm">
                  <span class="t-regular t-500">{{ category.count }}</span> / {{ category.total }}
                </span>
              </template>
            </div>

            <ul v-if="!category.collapsed && !isEmpty(category.results)" class="list-unstyled mb-0" :title="category.name">
              <li v-for="(result, i) in category.results" :key="i" class="list-item">
                <checkbox v-if="!result.hide"
                  :name="`${scope}_${result.value}`"
                  :bucket="result"
                  :filterName="scope"
                  :isWithTotalCount="true"
                  @selected="handleCheckbox(result, scope, category)"
                  class="my-0">
                </checkbox>
              </li>
            </ul>

            <spinner v-if="category.isBucketsLoading && !error" :containerClass="`width--50px min-height--150px`" />
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-secondary pull-right mt-5" @click="$events.$emit('modal:close')">
            Close
          </button>
        </div>
      </div>
    </div>

    <!-- render spinner -->
    <spinner v-if="!isLoaded && !error" :containerClass="`width--50px min-height--150px`" />
  </section>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import Spinner from '@/components/shared/Spinner'
import Checkbox from '@/components/search/Checkbox'
import CheckboxShared from '@/components/shared/form/Checkbox'

import SearchService from '@/services/search'
import debounce from '@/mixins/debounce'

export default {
  name: 'searchBrowse',
  components: {
    StaticMessages,
    Spinner,
    Checkbox,
    CheckboxShared
  },
  props: {
    scope: {
      type: String,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoaded: false,
      error: null,
      categories: [],
      cols: 3,
      sort: 'results',
      filter: 'all',
      browseState: {},
      isOnlyWithNullValues: true
    }
  },
  mounted () {
    this.browseState = { ...this.state }
    this.fetchCategories()
  },
  computed: {
    humanReadableScope () {
      if (this.scope.includes('countries')) {
        return 'countries'
      }
      return this.$options.filters.humanize(this.scope).toLowerCase()
    },
    hasFilters () {
      // Right now, only the organisations scope has filters. When we have more similar browsable categories with filtering
      // we should improve this logic
      return this.scope === 'organisations'
    },
    showCheckAllBox () {
      return this.scope === 'countries' || this.scope.includes('countries')
    },
    columns () {
      let columns = []
      let mid = Math.ceil(this.computedCategories.length / this.cols)
      for (let col = 0; col < this.cols; col++) {
        columns.push(this.computedCategories.slice(col * mid, col * mid + mid))
      }
      return columns
    },
    computedCategories () {
      // Make shallow copy of categories
      let filteredCategories = [...this.categories]

      for (let category of filteredCategories) {
        if (this.hasFilters && Array.isArray(category.values)) {
          // Remap values array to object key-value pair, for easier rendering and applying filters
          category.values = {
            academic: category.values.find(e => e.name === 'academic') || { name: 'academic', count: 0, total: 0 },
            other: category.values.find(e => e.name === 'other') || { name: 'other', count: 0, total: 0 }
          }
        }
        // Sorting of results within a category
        if (!this.isEmpty(category.results)) {
          // Sort results within each category
          category.results = Object.assign([], this.sortResults(category.results))

          // Filter out academic/other organisation types based on the filter and show correct counts based on filter
          if (this.hasFilters) {
            for (let result of category.results) {
              let sourceCategory = this.getSourceCategory(result)

              if (this.filter === 'academic' && sourceCategory !== 'academic') {
                result.hide = true
              } else if (this.filter === 'other' && sourceCategory === 'academic') {
                result.hide = true
              } else {
                result.hide = false
              }
            }
          }
        }
      }
      // Finally sort categories based on their 'total' count
      filteredCategories = filteredCategories.sort((a, b) => b.total - a.total)

      return filteredCategories
    }
  },
  methods: {
    checkAll (e, category) {
      category.collapsed = false
      if (!category.results) {
        this.$set(category, 'isBucketsLoading', true)
        this.browseState.browse_result_for = category.name

        // this.fetchDetails(category)
        SearchService.browseDetails(this.browseState).then(results => {
          this.$set(category, 'results', results)
          this.$set(category, 'isBucketsLoading', false)
          this.processCheckAll(e.target.checked, category)
        }).catch(err => {
          this.error = this.handleError(err)
        })
      } else {
        this.processCheckAll(e.target.checked, category)
      }
    },
    processCheckAll (isChecked, category) {
      category.results.forEach(result => {
        result.active = isChecked
      })
      this.$set(category, 'isBucketsLoading', false)
      this.$events.$emit('filter:changed:browse', category.results, this.browseState.browse)
    },
    fetchCategories () {
      this.browseState.browse = this.scope
      SearchService.browseCategories(this.browseState).then(categories => {
        // Initialise all categories to be collapsed
        for (let c of categories) c.collapsed = true
        this.categories = categories
        this.isLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isLoaded = !this.isEmpty(this.error)
      })
    },
    forceRerender () {
      this.$forceUpdate()
    },
    fetchDetails (category) {
      this.browseState.browse_result_for = category.name
      SearchService.browseDetails(this.browseState).then(results => {
        this.$set(category, 'results', results)
        this.$set(category, 'isBucketsLoading', false)
        if (this.showCheckAllBox) this.handleIndeterminate(category)
      }).catch(err => {
        this.error = this.handleError(err)
        this.$set(category, 'isBucketsLoading', false)
      })
    },
    toggleCollapse (category) {
      category.collapsed = !category.collapsed
      this.forceRerender()
      if (!category.collapsed && !category.results) {
        this.$set(category, 'isBucketsLoading', true)
        this.fetchDetails(category)
      }
    },
    toggleWithOrWithoutResults () {
      this.isLoaded = false
      this.categories = []
      this.browseState.browse_no_results = !this.browseState.browse_no_results
      this.fetchCategories()
    },
    handleIndeterminate (category) {
      // Find target checkbox we want to manipulate
      // Because the $refs are inside a v-for loop, they are put in an array by Vue, instead of directly returning the element.
      // Hence the this.$refs[refName][0] format (https://vuejs.org/v2/api/#ref)
      const targetElement = this.$refs[`checkall_${category.name}`] && this.$refs[`checkall_${category.name}`][0]
      // If we can't find the target, no point in doing anything else
      if (!targetElement) return

      const isAllTrue = category.results.every(val => val.active === true)
      const isAllFalse = category.results.every(val => val.active === false)
      if (isAllTrue) {
        // if every country is checked
        targetElement.checked = true
        targetElement.indeterminate = false
      } else if (isAllFalse) {
        // if every country is unchecked
        targetElement.checked = false
        targetElement.indeterminate = false
      } else {
        // If all are neither true nor false, it means _some_ are true
        targetElement.checked = false
        targetElement.indeterminate = true
      }
    },
    handleCheckbox (bucket, filterName, category) {
      bucket.active = !bucket.active
      if (this.showCheckAllBox) this.handleIndeterminate(category) // only works when there is checkboxall

      const allBuckets = []
      this.categories.forEach(cat => {
        if (cat.results) allBuckets.push(...cat.results)
      })
      this.commitResults(allBuckets, filterName)
    },
    commitResults: debounce(function (buckets, filterName) {
      this.$events.$emit('filter:changed:browse', buckets, filterName)
      this.forceRerender()
    }, 1000),
    sortResults (results) {
      if (this.sort === 'results') {
        return results.sort((a, b) => b.count - a.count)
      } else {
        // Sort results by Label if available, fall back to Value
        let sortKey = 'value'
        if (!this.isEmpty(results)) {
          if (results[0].hasOwnProperty('label') && results[0].label) {
            sortKey = 'label'
          }
        }
        return results.sort(function (a, b) {
          if (a[sortKey] < b[sortKey]) { return -1 }
          if (a[sortKey] > b[sortKey]) { return 1 }
          return 0
        })
      }
    },
    getSourceCategory (result) {
      if (!this.isEmpty(result.source.organisation)) {
        return result.source.organisation.category
      } else if (!this.isEmpty(result.source.research_group) && !this.isEmpty(result.source.research_group.organisation)) {
        return result.source.research_group.organisation.category
      } else if (!this.isEmpty(result.source.category)) {
        return result.source.category
      }

      return ''
    }
  }
}
</script>
