<template>
  <div class="p-4 d-flex align-items-center" v-if="isFetchingConfig">
    <spinner />
  </div>
  <section v-else>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-top">
        <dropdown
          class="mr-3"
          placement="bottom-end"
          noPadding
        >
          <template #button>
            <label class="select-label--small width--300px">
              <button class="form-control pr-4 t-left t-overflow"
                :disabled="!isLoaded"
                :value="currentTrend.key"
              >{{ currentTrend.label }}
              </button>
            </label>
          </template>
          <a v-for="trendCategory in configData"
            :key="trendCategory.key"
            href="#"
            @click.prevent="fetchTrends(trendCategory)"
            class="bg-grey-hover d-block py-2 px-3 t-primary d-flex"
          >
            <i class="fa fa-check t-secondary mr-2 mt-1" :class="{ 'invisible': currentTrend.key !== trendCategory.key }" />
            <span class="t-600 d-md-flex align-items-center">
              <span>{{ trendCategory.label }}</span>
            </span>
          </a>
        </dropdown>
        <div>
          <h2 class="t-500 mb-0">
            Trends {{ state.keyword ? `for ${state.keyword}` : '' }} in {{ getIndexAsString(state.index) }}, by {{ currentTrend.date_label }}
            <span
                v-if="activeFilters.length > 0"
                v-tooltip="activeFilters.join(', ')"
            > - {{ activeFilters.length }} {{ 'filter' | pluralize(activeFilters.length) }}</span>
            <span
              v-if="state.exclude.length > 0"
              v-tooltip="state.exclude.join(', ')"
            > - {{ state.exclude.length }} {{ 'exclude' | pluralize(state.exclude.length) }}</span>
          </h2>
          <span v-if="!showNormalizedCount">{{ subTitle }}</span>
          <span v-else><b>100% is defined as:</b> {{ altSubTitle }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <checkbox
          v-tooltip="checkboxTooltip"
          class="py-2 px-2 rounded bg-grey"
          :text="checkboxValue"
          :name="'toggle_normalization'"
          :value="showNormalizedCount"
          @toggle="toggleNormalisation"
        />
      </div>
    </div>
    <hr class="hr-small mt-4 mb-3">
    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px pt-4`" />

    <div class="row small-gutters" v-if="isLoaded">
      <div class="col-12 col-xs-6 col-lg-4 col-xl-3 col-xxl-2"
        v-for="trend in trends[currentTrend.key]"
        :key="trend.name"
      >
        <trend
          :state="state"
          :trend="trend"
          :tooltipSuffix="currentTrend.tooltip_suffix"
          :showNormalizedCount="showNormalizedCount"
        />
      </div>
      <div class="col-12 d-flex justify-content-end align-items-center mt-4">
        <checkbox
          v-tooltip="checkboxTooltip"
          class="py-2 px-3 rounded bg-grey mr-3"
          :text="checkboxValue"
          :name="'toggle_normalization'"
          :value="showNormalizedCount"
          @toggle="toggleNormalisation"
        />
        <button class="btn btn-primary px-3" @click="$events.$emit('modal:close')" type="button">Close</button>
      </div>
    </div>

  </section>
</template>

<script>
import Trend from '@/components/shared/chart/Trend'
import Spinner from '@/components/shared/Spinner'
import Dropdown from '@/components/shared/form/Dropdown'
import Checkbox from '@/components/shared/form/Checkbox2'

import SearchService from '@/services/search'

export default {
  name: 'trendsModal',
  components: {
    Spinner,
    Dropdown,
    Trend,
    Checkbox
  },
  props: {
    filter: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isFetchingConfig: true,
      configData: {},
      isLoaded: false,
      trends: {},
      currentTrend: {},
      showNormalizedCount: true
    }
  },
  mounted () {
    SearchService.getTrendsConfiguration({
      ...this.state,
      trend: this.filter
    }).then(response => {
      this.configData = response.sort((a, b) => a.order - b.order)
      this.isFetchingConfig = false

      this.currentTrend = this.configData.find(trend => trend.key === this.filter)
      this.fetchTrends()
    })
  },
  computed: {
    activeFilters () {
      const filters = this.state.filters
      if (this.isEmpty(filters)) return []

      return this.getActiveFilters(filters)
    },
    subTitle () {
      const suffix = this.currentTrend.subtitle_suffix
      const baseString = `Based on all ${this.getIndexAsString(this.state.index)}`
      return suffix.length === 0 ? `${baseString} that match your search.` : `${baseString} ${suffix}.`
    },
    altSubTitle () {
      return this.subTitle.replace('Based on ', '')
    },
    checkboxValue () {
      return 'Show as percentages (%)'
    },
    checkboxTooltip () {
      return `<b>Show as percentages:</b> Well-suited for comparing multiple charts. When enabled, it shows the percentage of ${this.getIndexAsString(this.state.index)} by <i>${this.currentTrend.label}</i>, by ${this.currentTrend.date_label}.<br/>100% is defined as: ${this.altSubTitle}`
    }
  },
  methods: {
    fetchTrends (trendCategory) {
      if (trendCategory) this.currentTrend = trendCategory
      if (this.trends[this.currentTrend.key]) return
      this.isLoaded = false

      SearchService.getTrendsData({
        ...this.state,
        trend: this.currentTrend.key
      }).then(response => {
        this.isLoaded = true
        if (this.currentTrend.bucket_order) {
          response = response.sort((a, b) => a.order - b.order)
        } else {
          response = response.sort((a, b) => b.active - a.active || b.total - a.total)
        }
        this.trends[this.currentTrend.key] = response
      })
    },
    toggleNormalisation () {
      this.showNormalizedCount = !this.showNormalizedCount
    }
  }
}
</script>
