<template>
  <div class="preview-block">
    <div
      class="title-collapsible m-0"
      @click="toggleExpanded"
    >
      <i class="fa" :class="{ 'fa-caret-down': !collapsed, 'fa-caret-right': collapsed }"></i>
      <span v-html="title"></span>
    </div>
    <div
      class="container"
      :style="{ maxHeight: collapsed ? `${previewHeight}px` : `${contentHeight}px`}"
      @click="handleContainerClick"
      ref="container"
    >
      <div class="truncated-content" @click.stop="handleContentClick">
        <slot></slot>
      </div>
      <div v-if="collapsed" class="fade-out"></div>
    </div>
  </div>
</template>

<script>
/**
 * A collapsible component for any kind of content, similar to a BlockCollapsible.
 * In its collapsed form, a fixed-height preview of the content is shown.
 *
 * Click anywhere (the header or the preview) to expand the content.
 */
export default {
  name: 'BlockPreviewCollapsible',
  props: {
    title: {
      type: String,
      required: true
    },
    previewHeight: { // In collapsed form, the height of the preview in px. (0 will work too)
      type: Number,
      default: 100
    },
    openCollapsed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      collapsed: this.openCollapsed,
      contentHeight: 0
    }
  },
  mounted () {
    this.calculateContentHeight()
  },
  methods: {
    toggleExpanded () {
      this.collapsed = !this.collapsed
    },
    expand () {
      this.collapsed = false
    },
    collapse () {
      this.collapsed = true
    },
    setPreviewHeight (pixels) {
      this.previewHeight = pixels
    },
    handleContainerClick () {
      if (this.collapsed) {
        this.toggleExpanded()
      }
    },
    handleContentClick (event) {
      if (this.collapsed) {
        event.stopPropagation()
        this.toggleExpanded()
      }
    },
    calculateContentHeight () {
      // Compute the content's height in expanded state
      // This is purely needed to make the max-height CSS animation work.
      this.$nextTick(() => {
        const container = this.$refs.container
        this.contentHeight = container.scrollHeight + 50
        // The extra 50px is just to err on the safe side (for edge cases where the computation isn't bulletproof)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loaders/_colors.scss";

.preview-block {
  transition: border-color 0.3s ease;
}

.container {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0;
}

.truncated-content {
  overflow: auto;
  padding: 5px;
}

.fade-out {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
}

.preview-block:hover .title-collapsible {
  background-color: $grey-lightest;
}
</style>
