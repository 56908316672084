<template>
  <dropdown
    placement="bottom"
    noPadding
    class="d-inline-block y-a-label mr-1 mb-1"
  >
    <template #button>
      <link-button class="t-sm px-2 d-block" v-tooltip.bottom="`Click to see options…`" v-html="label.value" />
    </template>
    <div class="t-sm p-2" aria-labelledby="pubtator-item-dropdown">
      <router-link class="link-button" :to="updatedSearchUrl">
        <i class="mr-2 fa fa-filter t-secondary" /> Show current results with this {{title.toLowerCase() | singularize}}
      </router-link>
      <router-link class="link-button t-overflow" :to="newSearchUrl">
        <i class="mr-2 fa fa-search-plus t-secondary" />Start a new search for <span class="t-500 ml-1" v-html="label.value" />
      </router-link>
      <div v-if="label.url" class="dropdown-divider"></div>
      <a v-if="label.url" class="link-button" :href="label.url" target="_blank">
        <i class="mr-2 fa fa-link t-secondary" />{{externalUrlLabel}}
      </a>
    </div>
  </dropdown>
</template>

<script>
import Dropdown from '@/components/shared/form/Dropdown'
import LinkButton from '@/components/shared/form/LinkButton'

export default {
  name: 'labelItem',
  props: {
    title: {
      type: String,
      required: true
    },
    label: {
      type: Object,
      required: true
    },
    externalUrlLabel: {
      type: String,
      required: false,
      default: ''
    },
    filterKey: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    Dropdown,
    LinkButton
  },
  computed: {
    updatedSearchUrl () {
      const buckets = this.$route.params.buckets
      const bucketTitle = this.filterKey || this.title.replace(/\s+/g, '_').toLowerCase()
      const sluggedLabel = this.$options.filters.slugify(this.label.value)

      let bucketsArray = []
      if (!this.isEmpty(buckets)) {
        bucketsArray = Array.isArray(buckets) ? [...buckets] : [...buckets.split('/')]
      }

      const findBucket = !this.isEmpty(bucketsArray) && bucketsArray.find(bucket => bucket.includes(`${bucketTitle}:`))
      const bucketIndex = bucketsArray.indexOf(findBucket)

      let updatedBucket = ''
      if (!findBucket) {
        updatedBucket = `${bucketTitle}:${sluggedLabel}`
      } else {
        if (!findBucket.includes(`${sluggedLabel}`)) updatedBucket = `${findBucket},${sluggedLabel}`
        else updatedBucket = findBucket
      }

      bucketIndex < 0 ? bucketsArray.push(updatedBucket) : bucketsArray[bucketIndex] = updatedBucket

      // Generates the correct route name for new search url where user will be directed to when pubtator label item is clicked
      // Written specifically in the case of researcher or RG profile page
      let routeName = this.$route.name
      if (routeName !== 'search' && !routeName.includes('.explore')) {
        routeName = routeName.split('.')[0].concat('.explore')
      }
      return {
        name: routeName,
        params: { category: this.$route.params.category, buckets: bucketsArray },
        query: { keyword: this.$route.query.keyword, sort: this.$route.query.sort, updated: true }
      }
      // const route = this.$router.resolve({
      //   name: routeName,
      //   params: { category: 'publications', buckets: bucketsArray },
      //   query: { keyword: this.$route.query.keyword, sort: this.$route.query.sort }
      // })
      // return route.href
    },
    newSearchUrl () {
      return {
        name: 'search',
        params: { category: this.$route.params.category },
        query: { keyword: this.label.value, updated: true }
      }
      // const route = this.$router.resolve({ name: 'search', params: { category: this.$route.params.category }, query: { keyword: this.label.value } })
      // return route.href
    }
  }
}
</script>
