<template>
  <transition v-if="isModalShow" name="fade">
    <div class="modal modal-mask" tabindex="-1" role="dialog" aria-hidden="true" aria-modal="true" @click.self="closeByClickOutside">
      <div
        role="document"
        :class="{
          'modal-dialog h-100 my-0 mx-auto d-flex flex-column justify-content-center': isNormalModal,
          'modal-dialog h-100 my-0 mx-auto d-flex flex-column justify-content-center modal-dialog-l': isModalLarge,
          'modal-dialog h-100 my-0 mx-auto d-flex flex-column justify-content-center modal-dialog-xl': isModalExtraLarge,
          'modal-dialog modal-dialog-fs': isModalFs,
          'modal-dialog mx-auto d-flex flex-column justify-content-center modal-dialog-l': isModalFixed
        }">
        <div class="modal-content">
          <i v-if="!hideCloseIcon" class="fa fa-close close-dialog" @click="close"></i>
          <div id="modal-body"
            class="modal-body"
            :class="{
              'disabled-overflow': isDisabledOverflow,
              'px-4 py-4': !isResetPadding,
              'px-0 py-0': isResetPadding,
              'vh-100': isFullHeight
            }">

            <!-- render create login -->
            <login v-if="isLogin" />

            <!-- render publication create -->
            <publication-show v-if="isPublicationShow" :slug="slug"/>

            <!-- render research topic -->
            <research-topic v-if="isResearchTopic" :slug="slug" />

            <!-- render clinical trial -->
            <clinical-trial v-if="isClinicalTrial" :slug="slug" />

            <!-- render project -->
            <project v-if="isProject"  :slug="slug"/>

            <!-- render patent -->
            <patent v-if="isPatent" :slug="slug"/>

            <!-- render news modal -->
            <news v-if="isNews" :slug="slug"/>

            <!-- render all filters country -->
            <filter-browse v-if="isFilterCountry" :scope="filter" :state="state"/>

            <!-- render show contacts -->
            <contact-details v-if="isContact" :item="item" :scope="scope" />

            <!-- render show contacts -->
            <contact-mail v-if="isContactMail"
              :contacts="item"
              :name="name"
              :phone="phone"
              :scope="scope"
              :slug="slug"
              :state="state">
            </contact-mail>

            <exclude-modal
              v-if="isExcludeModal"
              :currentExcludes="items"
            />

            <search-by-field-modal
              v-if="isSearchByFieldsModal"
              :fields="items"
              :index="category"
            />

            <pro-generic-modal
              v-if="isProGenericModal"
              :category="category"
            />

            <trends-modal
              v-if="isTrendsModal"
              :filter="filter"
              :state="state"
              :category="category"
            />

            <export-modal
              v-if="isExportModal"
              :count="count"
              :scope="scope"
              :state="state"
            />

            <synonyms-modal
              v-if="isSynonyms"
              :state="state"
            />

            <landscape-preview
              v-if="isLandscapePreview"
            />

            <!-- modals that are only visible for a signed in user -->
            <template v-if="$currentUser">
              <!-- render twitter -->
              <twitter v-if="isTwitter" :twitterId="twitterId"/>

              <!-- render create bookmark folder -->
              <bookmark-folder v-if="isBookmarkFolder"
                :bookmarkFolder="bookmarkFolder"
                :currentItem="item"
                :scope="scope"
                :state="state"
                :isBulkSave="isBulkSave"
                :count="count"
              />

              <!-- render researcher intro -->
              <researcher-edit-single v-if="isResearcherEditSingle"
                :researcher="researcher"
                :user="user"
                :scope="researcherFocus"
                @opened="isDisabledOverflow = true"
                @closed="isDisabledOverflow = false">
              </researcher-edit-single>

              <!-- render researcher intro -->
              <researcher-edit v-if="isResearcherEdit"
                :researcher="researcher"
                :focusOn="researcherFocus"
                @opened="isDisabledOverflow = true"
                @closed="isDisabledOverflow = false">
              </researcher-edit>

              <!-- render researcher intro -->
              <researcher-edit-contact v-if="isResearcherEditContact"
                :researcher="researcher"
                :user="user">
              </researcher-edit-contact>

              <!-- render researcher intro -->
              <user-connect v-if="isUserConnect"
                :connectData="userConnectData"
                :scope="userConnectScope">
              </user-connect>

              <!-- render create list -->
              <discipline v-if="isDiscipline" :currentDisciplines="researcherDisciplines"/>

              <!-- render question create -->
              <answer-create v-if="isAnswerCreate" :community="community" :question="question" />

              <!-- render organisation request accept -->
              <organisation-request-accept v-if="isOrganisationRequestAccept" :researcher="researcher" />

              <!-- render organisation -->
              <organisation-create v-if="isOrganisation" :researcher="researcher" :user="user" />

              <!-- render research group create -->
              <research-group-create v-if="isResearchGroupCreate" :researchGroup="researchGroup" />

              <!-- render invite create -->
              <invite-create v-if="isInviteCreate"
                :researchGroup="researchGroup"
                :community="community"
                :bookmarkFolder="bookmarkFolder">
              </invite-create>

              <!-- render community create -->
              <community-create v-if="isCommunityCreate" :community="community" :isSubCommunity="subCommunity"/>

              <!-- render question create -->
              <question-create v-if="isQuestionCreate" :community="community" :question="question" />

              <!-- render research topic create -->
              <research-topic-create v-if="isResearchTopicCreate" :researchGroup="researchGroup" :currentResearchTopic="researchTopic" />

              <!-- render join community confirm  -->
              <join-confirm v-if="isJoinConfirm" :community="community" />

              <!-- render invite research_group confirm  -->
              <invite-confirm v-if="isInviteConfirm" :researchGroup="researchGroup" :community="community"  :invite="invite" />

              <!-- render invite request -->
              <invite-request v-if="isInviteRequest" :slug="slug" :researchGroup="researchGroup" :community="community" :scope="scope" />

              <!-- render secondary email -->
              <secondary-email v-if="isSecondaryEmail" :user="researcher" :category="category" />

              <!-- render account options -->
              <verify-options v-if="isAccountOptions" :user="user" :researcher="researcher" />

              <!-- render question create -->
              <permissions v-if="isPermissions" :community="community" :researchGroup="researchGroup" :user="researcher" />

              <!-- render invite create -->
              <create-bookmark-invite v-if="isCreateBookmarkInvite"
                :bookmarkFolder="bookmarkFolder">
              </create-bookmark-invite>

              <!-- render invite create -->
              <bookmark-contributors v-if="isBookmarkContributors"
                :bookmarkFolder="bookmarkFolder">
              </bookmark-contributors>

              <map-modal
                v-if="isMapModal"
                :filter="filter"
                :state="state"
                :mapType="category"
              />

              <ai-analysis-modal
                v-if="isAiAnalysisModal"
                :state="state"
                :count="count"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BookmarkFolder from '@/components/bookmark_folder/Create'
import Twitter from '@/components/login/TwitterModal'
import Login from '@/components/login/LoginModal'
import ResearcherEdit from '@/components/researcher/Edit'
import ResearcherEditSingle from '@/components/researcher/EditSingle'
import ResearcherEditContact from '@/components/contact/Edit'
import UserConnect from '@/components/user/Connect'
import OrganisationCreate from '@/components/organisation/Create'
import OrganisationRequestAccept from '@/components/organisation_request/AcceptModal'
import PublicationShow from '@/components/publication/Modal'
import Discipline from '@/components/discipline/Create'
import ResearchTopic from '@/components/research_topic/Modal'
import ClinicalTrial from '@/components/clinical_trial/Modal'
import Project from '@/components/project/Modal'
import Patent from '@/components/patent/Modal'
import ResearchTopicCreate from '@/components/research_topic/Create'
import ResearchGroupCreate from '@/components/research_group/Create'
import JoinConfirm from '@/components/join/Confirm'
import InviteCreate from '@/components/invite/Create'
import InviteConfirm from '@/components/invite/Confirm'
import InviteRequest from '@/components/invite_request/Create'
import CommunityCreate from '@/components/community/Create'
import QuestionCreate from '@/components/community/questions/Create'
import AnswerCreate from '@/components/community/questions/answers/Create'
import Permissions from '@/components/shared/crud/Permissions'
import SecondaryEmail from '@/components/user/settings/SecondaryEmailCreate'
import VerifyOptions from '@/components/user/settings/VerifyOptions'
import FilterBrowse from '@/components/search/browse/Browse'
import ContactDetails from '@/components/contact/Details'
import ContactMail from '@/components/contact/Mail'
import CreateBookmarkInvite from '@/components/invite/CreateBookmarkInvite'
import BookmarkContributors from '@/components/bookmark_folder/Contributors'
import ExportModal from '@/components/search/ExportModal'
import ExcludeModal from '@/components/search/ExcludeModal'
import SearchByFieldModal from '@/components/search/SearchByFieldModal'
import MapModal from '@/components/search/maps/MapModal'
import TrendsModal from '@/components/search/trends/TrendsModal'
import ProGenericModal from '@/components/pro/GenericModal'
import AiAnalysisModal from '@/components/search/ai_analysis/AiAnalysisModal'
import SynonymsModal from '@/components/search/Synonyms'
import News from '@/components/news/Modal'
import LandscapePreview from '@/components/search/landscape/PreviewModal'

export default {
  name: 'modal',
  components: {
    AiAnalysisModal,
    SearchByFieldModal,
    ExcludeModal,
    ExportModal,
    BookmarkFolder,
    Twitter,
    Login,
    ResearcherEdit,
    ResearcherEditSingle,
    ResearcherEditContact,
    UserConnect,
    OrganisationCreate,
    OrganisationRequestAccept,
    PublicationShow,
    Discipline,
    ResearchTopic,
    ResearchTopicCreate,
    ResearchGroupCreate,
    JoinConfirm,
    InviteCreate,
    InviteConfirm,
    InviteRequest,
    CommunityCreate,
    QuestionCreate,
    AnswerCreate,
    Permissions,
    SecondaryEmail,
    ClinicalTrial,
    Project,
    Patent,
    VerifyOptions,
    FilterBrowse,
    ContactDetails,
    ContactMail,
    CreateBookmarkInvite,
    BookmarkContributors,
    MapModal,
    ProGenericModal,
    TrendsModal,
    SynonymsModal,
    News,
    LandscapePreview
  },
  data () {
    return {
      isAiAnalysisModal: false,
      isExcludeModal: false,
      isExportModal: false,
      isProGenericModal: false,
      isBulkSave: false,
      isDisabledOverflow: false,
      isBookmarkFolder: false,
      isLogin: false,
      isTwitter: false,
      isOrganisation: false,
      isOrganisationRequestAccept: false,
      isResearcherEdit: false,
      isResearcherEditSingle: false,
      isResearcherEditContact: false,
      isUserConnect: false,
      isPublicationShow: false,
      isDiscipline: false,
      isResearchTopic: false,
      isClinicalTrial: false,
      isProject: false,
      isPatent: false,
      isNews: false,
      isResearchTopicCreate: false,
      isResearchGroupCreate: false,
      isCommunityCreate: false,
      isJoinConfirm: false,
      isInviteCreate: false,
      isInviteConfirm: false,
      isInviteRequest: false,
      isQuestionCreate: false,
      isAnswerCreate: false,
      isPermissions: false,
      isSecondaryEmail: false,
      isModalShow: false,
      isNormalModal: false,
      isModalLarge: false,
      isModalExtraLarge: false,
      isModalFs: false,
      isModalFixed: false,
      isResetPadding: false,
      isFilterCountry: false,
      isAccountOptions: false,
      isContact: false,
      isContactMail: false,
      isMessageCreate: false,
      isMapModal: false,
      isTrendsModal: false,
      isSearchByFieldsModal: false,
      isSynonyms: false,
      isLandscapePreview: false,
      organisationId: '',
      publicationId: '',
      researcherFocus: '',
      userConnectData: '',
      researcherDisciplines: [],
      userConnectScope: '',
      twitterId: '',
      slug: '',
      bookmarkFolder: {},
      researchGroup: {},
      community: {},
      subCommunity: false,
      question: {},
      organisation: {},
      researcher: {},
      user: {},
      researchTopic: {},
      invite: {},
      scope: '',
      filter: '',
      category: '',
      state: {},
      item: {},
      email: '',
      phone: '',
      name: '',
      items: [],
      isCreateBookmarkInvite: false,
      isBookmarkContributors: false,
      count: 0,
      isFullHeight: false,
      hideCloseIcon: false,
      disableClickOutside: false
    }
  },
  created () {
    this.$events.$on('modal:close', () => {
      this.close()
    })

    this.$events.$on('modal:bookmark_folder:open:create', ({
      bookmarkFolder,
      item,
      scope,
      state,
      isBulkSave,
      count
    } = {}) => {
      this.open('large')
      if (bookmarkFolder) this.bookmarkFolder = bookmarkFolder
      this.isBookmarkFolder = true
      this.item = item
      this.scope = scope
      this.state = state
      this.isBulkSave = isBulkSave
      this.count = count
    })

    this.$events.$on('modal:discipline:open', (disciplines) => {
      this.open('fs')
      this.isDiscipline = true
      this.researcherDisciplines = disciplines
    })

    this.$events.$on('modal:twitter:open', (username) => {
      this.open()
      this.isTwitter = true
      this.twitterId = username
    })

    this.$events.$on('modal:login:open', () => {
      this.open()
      this.isLogin = true
    })

    // catch organisation event
    this.$events.$on('modal:organisation:create:open', (user, researcher) => {
      this.open('large')
      this.isOrganisation = true
      this.researcher = researcher
      this.user = user
    })

    // catch organisation event
    this.$events.$on('modal:organisation_request:accept:open', () => {
      this.open('large')
      this.isOrganisationRequestAccept = true
    })

    // catch researcher picture event
    this.$events.$on('modal:researcher:edit:open', (researcher, focus) => {
      this.open('large')
      this.isResearcherEdit = true
      this.researcher = researcher
      this.researcherFocus = focus
    })

    // catch researcher picture event
    this.$events.$on('modal:researcher:edit:contact:open', (researcher, user) => {
      this.open('large')
      this.isResearcherEditContact = true
      this.researcher = researcher
      this.user = user
    })

    this.$events.$on('modal:researcher:edit:single:open', (user, researcher, scope, modalType = 'large') => {
      this.open(modalType)
      this.isResearcherEditSingle = true
      this.researcher = researcher
      this.user = user
      this.researcherFocus = scope
    })

    // catch researcher connect event
    this.$events.$on('modal:user:connect:open', (connectData, scope) => {
      this.open('large')
      this.isUserConnect = true
      this.userConnectData = connectData
      this.userConnectScope = scope
    })

    // catch publication event
    this.$events.$on('modal:publication:open', (slug) => {
      this.open('fs', true)
      this.isPublicationShow = true
      this.isResetPadding = true
      this.slug = slug
    })

    // catch clinical trial
    this.$events.$on('modal:clinical_trial:open', (slug) => {
      this.open('fs', true)
      this.isResetPadding = true
      this.isClinicalTrial = true
      this.slug = slug
    })

    // catch research topic
    this.$events.$on('modal:project:open', (slug) => {
      this.open('fs', true)
      this.isResetPadding = true
      this.isProject = true
      this.slug = slug
    })

    // catch research topic
    this.$events.$on('modal:patent:open', (slug) => {
      this.open('fs', true)
      this.isResetPadding = true
      this.isPatent = true
      this.slug = slug
    })

    // catch news modal
    this.$events.$on('modal:news:open', (slug) => {
      this.open('fs', true)
      this.isResetPadding = true
      this.isNews = true
      this.slug = slug
    })

    // catch research topic
    this.$events.$on('modal:research_topic:open', (slug) => {
      this.open('fs', true)
      this.isResetPadding = true
      this.isResearchTopic = true
      this.slug = slug
    })

    // catch research topic
    this.$events.$on('modal:research_topic:create:open', (researchGroup, topic) => {
      this.open('large')
      this.isResearchTopicCreate = true
      this.researchGroup = researchGroup
      this.researchTopic = topic
    })

    // catch research group create
    this.$events.$on('modal:research_group:open:create', (researchGroup) => {
      this.open('large')
      this.isResearchGroupCreate = true
      this.researchGroup = researchGroup
    })

    // catch research group invite
    this.$events.$on('modal:invite_request:open', (slug, scope, researchGroup = {}, community = {}) => {
      this.open()
      this.isInviteRequest = true
      this.slug = slug
      this.researchGroup = researchGroup
      this.community = community
      this.scope = scope
    })

    // catch research group invite
    this.$events.$on('modal:invite:open:confirm', (researchGroup, community, invite) => {
      this.open('large')
      this.isInviteConfirm = true
      this.researchGroup = researchGroup
      this.community = community
      this.invite = invite
    })

    // catch community invite
    this.$events.$on('modal:join:open:confirm', (community) => {
      this.open('large')
      this.isJoinConfirm = true
      this.community = community
    })

    // catch research_group invite
    this.$events.$on('modal:invite:open:create', (researchGroup, community, bookmarkFolder = {}) => {
      this.open('large')
      this.isInviteCreate = true
      this.researchGroup = researchGroup
      this.community = community
      this.bookmarkFolder = bookmarkFolder
    })

    // catch community create
    this.$events.$on('modal:community:open:create', (community, subCommunity) => {
      this.open('large')
      this.isCommunityCreate = true
      this.community = community
      this.subCommunity = subCommunity
    })

    // catch community question create
    this.$events.$on('modal:community:question:open:create', (community, question) => {
      question = question || {}

      this.open('large')
      this.isQuestionCreate = true
      this.community = community
      this.question = question
    })

    // catch community question create
    this.$events.$on('modal:community:answer:open:create', (community, question) => {
      question = question || {}

      this.open('large')
      this.isAnswerCreate = true
      this.community = community
      this.question = question
    })

    this.$events.$on('modal:permissions:open', (community, researchGroup, user) => {
      this.open()
      this.isPermissions = true
      this.community = community
      this.researchGroup = researchGroup
      this.researcher = user
    })

    this.$events.$on('modal:user:secondary_email:open', (user, category) => {
      this.open()
      this.isSecondaryEmail = true
      this.researcher = user
      this.category = category
    })

    this.$events.$on('modal:user:verify:options', (researcher, user) => {
      this.open('large')
      this.isAccountOptions = true
      this.researcher = researcher
      this.user = user
    })

    this.$events.$on('modal:filter:browse:open', (filter, state) => {
      this.open('fs')
      this.filter = filter
      this.state = state
      this.isFilterCountry = true
    })

    this.$events.$on('modal:contact:open', (item, scope) => {
      this.open()
      this.item = item
      this.scope = scope
      this.isContact = true
    })

    this.$events.$on('modal:contact:mail:create', (item, phone, scope, slug, name, state = {}) => {
      this.open('large')
      this.item = item
      this.name = name
      this.phone = phone
      this.scope = scope
      this.slug = slug
      this.state = state
      this.isContactMail = true
    })

    // catch research_group invite
    this.$events.$on('modal:invite:bookmark:open:create', (bookmarkFolder) => {
      if (!bookmarkFolder) return
      this.open('xl')
      this.isCreateBookmarkInvite = true
      this.bookmarkFolder = bookmarkFolder
    })

    this.$events.$on('modal:invite:bookmark:open:contributors', (bookmarkFolder) => {
      if (!bookmarkFolder) return
      this.open('large')
      this.isBookmarkContributors = true
      this.bookmarkFolder = bookmarkFolder
    })

    this.$events.$on('modal:pro_generic:open', (category) => {
      this.open('large')
      this.category = category
      this.isProGenericModal = true
    })

    this.$events.$on('modal:export_csv', ({
      count, scope, state
    }) => {
      this.open('large')
      this.isExportModal = true
      this.count = count
      this.scope = scope
      this.state = state
    })

    this.$events.$on('modal:exclude', (excludes) => {
      this.open('large')
      this.isExcludeModal = true
      this.hideCloseIcon = true
      this.items = excludes
    })

    this.$events.$on('modal:search_by_fields', (items, index) => {
      this.open('large')
      this.isSearchByFieldsModal = true
      this.hideCloseIcon = true
      this.items = items
      this.category = index
    })

    this.$events.$on('modal:filter:map:open', (filterName, state, map) => {
      this.open('fs', true)
      this.isMapModal = true
      this.filter = filterName
      this.state = state
      this.category = map
    })

    this.$events.$on('modal:filter:trends:open', (filterName, state, trendCategory) => {
      this.open('fs', true)
      this.isTrendsModal = true
      this.filter = filterName
      this.state = state
      this.category = trendCategory
    })

    this.$events.$on('modal:ai_analysis:open', ({
      count, state
    }) => {
      this.open('fixed')
      this.hideCloseIcon = true
      this.disableClickOutside = true
      this.isAiAnalysisModal = true
      this.state = state
      this.count = count
    })

    this.$events.$on('modal:synonyms:open', (state) => {
      this.open('large')
      this.state = state
      this.isSynonyms = true
    })

    this.$events.$on('modal:landscape:preview', () => {
      this.open('large')
      this.isLandscapePreview = true
    })

    document.addEventListener('keydown', (e) => {
      if (this.isModalShow && (e.key === 'Esc' || e.key === 'Escape')) this.close()
    })
  },
  methods: {
    open (type, isFullHeight) {
      document.body.classList.add('modal-open')
      this.clearData()
      if (type === 'fs') this.isModalFs = true
      else if (type === 'fixed') this.isModalFixed = true
      else if (type === 'large') this.isModalLarge = true
      else if (type === 'xl') this.isModalExtraLarge = true
      else this.isNormalModal = true
      this.isModalShow = true
      this.isFullHeight = isFullHeight
    },
    close () {
      document.body.classList.remove('modal-open')
      this.$nextTick(() => this.clearData())
    },
    clearData () {
      Object.assign(this.$data, this.$options.data())
    },
    closeByClickOutside () {
      // Event triggered by clicking outside of a modal. Usually closes the modal, unless disableClickOutside === true
      if (!this.disableClickOutside) this.close()
    }
  },
  destroyed () {
    this.$events.$off('modal:close')
    this.$events.$off('modal:bookmark_folder:open:create')
    this.$events.$off('modal:discipline:open')
    this.$events.$off('modal:login:open')
    this.$events.$off('modal:organisation:create:open')
    this.$events.$off('modal:organisation_request:accept:open')
    this.$events.$off('modal:publication:create:open')
    this.$events.$off('modal:twitter:open')
    this.$events.$off('modal:organisation:open')
    this.$events.$off('modal:community:answer:open:create')
    this.$events.$off('modal:permissions:open')
    this.$events.$off('modal:publication:open')
    this.$events.$off('modal:researcher:edit:open')
    this.$events.$off('modal:researcher:edit:contact:open')
    this.$events.$off('modal:researcher:edit:single:open')
    this.$events.$off('modal:user:connect:open')
    this.$events.$off('modal:user:verify:options')
    this.$events.$off('modal:user:secondary_email:open')
    this.$events.$off('modal:research_topic:open')
    this.$events.$off('modal:patent:open')
    this.$events.$off('modal:research_group:open:create')
    this.$events.$off('modal:invite:open:create')
    this.$events.$off('modal:invite:open:confirm')
    this.$events.$off('modal:join:open:confirm')
    this.$events.$off('modal:invite_request:open')
    this.$events.$off('modal:community:open:create')
    this.$events.$off('modal:community:question:open:create')
    this.$events.$off('modal:filter:browse:open')
    this.$events.$off('modal:contact:open')
    this.$events.$off('modal:contact:mail:create')
    this.$events.$off('modal:invite:bookmark:open:create')
    this.$events.$off('modal:invite:bookmark:open:contributors')
    this.$events.$off('modal:export_csv')
    this.$events.$off('modal:pro_generic:open')
    this.$events.$off('modal:exclude')
    this.$events.$off('modal:search_by_fields')
    this.$events.$off('modal:filter:map:open')
    this.$events.$off('modal:filter:trends:open')
    this.$events.$off('modal:synonyms:open')
    this.$events.$off('modal:news:open')
    this.$events.$off('modal:landscape:preview')
  }
}
</script>
