// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import router from './router'
import VueProgressBar from 'vue-progressbar'
import VueRouter from 'vue-router'
import VeeValidate from 'vee-validate'
import Meta from 'vue-meta'
import LocalStorage from '@/plugins/localstorage'
import Axios from '@/plugins/axios'
import Auth from '@/plugins/auth'
import Scripts from '@/plugins/scripts'
import MixPanel from '@/plugins/mixpanel'
import Can from '@/plugins/can'
import Pusher from '@/plugins/pusher'
import Intercom from '@/plugins/intercom'
import VueIntercom from '@/plugins/intercom-js'
import Prototype from '@/plugins/index'
import VTooltip from 'v-tooltip'
import VueClipboards from 'vue-clipboards'
import VuejsDialog from 'vuejs-dialog'
import FlagIcon from 'vue-flag-icon'
import ScrollTo from 'vue-scrollto'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import * as Sentry from '@sentry/browser'
import * as SentryIntegrations from '@sentry/integrations'
// AM-Charts
import { addLicense as am5License } from '@amcharts/amcharts5'

// App SASS
import '@/assets/scss/app.scss'

// Global filters / mixins / ...
import '@/filters'
import '@/mixins'
import '@/directives'
import '@/plugins/validator'
import store from '@/store'

// App component
import App from '@/App.vue'

// Set license
am5License('AM5C353234793')
am5License('AM5M353234793')

// custom local storage plugin
Vue.use(LocalStorage)
// add MixPanel integration
Vue.use(MixPanel)
// custom auth plugin
Vue.use(Auth)
// custom axios plugin
Vue.use(Axios)
// use the default router plugin
Vue.use(VueRouter)
// custom global prototypes
Vue.use(Prototype)
// add pusher
Vue.use(Pusher)
// https://github.com/baianat/vee-validate
Vue.use(VeeValidate, { fieldsBagName: 'formFields' })
// https://github.com/declandewet/vue-meta
Vue.use(Meta)
// custom can plugin
Vue.use(Can)
// tooltips (https://github.com/Akryum/v-tooltip)
Vue.use(VTooltip)
// copy to clipboard (https://github.com/zhuowenli/vue-clipboards)
Vue.use(VueClipboards)
// flagIcon (https://github.com/vikkio88/vue-flag-icon)
Vue.use(FlagIcon)
// scroll to helper (https://github.com/rigor789/vue-scrollto#readme)
Vue.use(ScrollTo)
// https://github.com/hilongjw/vue-progressbar
Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  thickness: '3px',
  location: 'top'
})

// import script as Google Analytics and HotJar
Vue.use(Scripts, {
  GAId: process.env.VUE_APP_GA_CODE
})

// https://github.com/Godofbrowser/vuejs-dialog
Vue.use(VuejsDialog, {
  loader: true,
  backdropClose: true,
  verification: 'DELETE'
})

// https://www.npmjs.com/package/vue-intercom
Vue.use(VueIntercom, {
  appId: process.env.VUE_APP_INTERCOM_API_KEY
})
Vue.use(Intercom)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  store,
  el: '#app',
  router,
  render: h => h(App),
  created: () => {
    if (process.env.VUE_APP_DEPLOY_ENV !== 'dev') {
      let debug = process.env.VUE_APP_DEPLOY_ENV !== 'production'
      Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_URL,
        release: `al-front@${process.env.VUE_APP_VERSION}`,
        debug: debug,
        integrations: [
          new SentryIntegrations.ExtraErrorData(),
          new SentryIntegrations.Vue({
            Vue,
            attachProps: true
          })
        ]
      })

      Sentry.configureScope(scope => {
        scope.setTag('environment', process.env.VUE_APP_DEPLOY_ENV)
      })
    }
  }
})
