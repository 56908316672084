<template>
  <div class="range-slider w-50 mb-3 ml-3">
    <p class="mb-0">
      {{ label }}
    </p>
    <div class="d-flex mt-2">
      <input
        v-model="sliderValue"
        class="range-slider__range"
        type="range"
        :min="min"
        :max="max"
        :step=1
        :style="{ width:width +'px', marginTop: '5px' }"
        @input="handleSliderInput"
        @mouseup="handleMouseUp"
        />
        <span class="ml-3">{{ sliderValue }}</span>
      </div>
  </div>
</template>

<script>
/**
 * A range slider component. It ensures the following:
 * - The slider's value will always be in range, even if the `min` or `max` prop values have changed dynamically.
 * - The user can always select the max. value, even if it doesn't correspond to a stepped value.
 *
 * Listen to slider:change-value to read the slider's current value
 */
export default {
  name: 'rangeSlider',
  props: {
    label: {
      type: String,
      default: 'Slider value:'
    },
    value: {
      type: Number,
      default: 0
    },
    width: { // Width in px of the slider itself (not the number next to it)
      type: Number,
      default: 200
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 10
    },
    step: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      sliderValue: this.value
    }
  },
  watch: {
    min (newMin) {
      if (this.sliderValue < newMin) {
        this.sliderValue = newMin
        this.$emit('slider:change-value', this.sliderValue)
      }
    },
    max (newMax) {
      if (this.sliderValue > newMax) {
        this.sliderValue = newMax
        this.$emit('slider:change-value', this.sliderValue)
      }
    }
  },
  methods: {
    setSliderValue (sliderValue) {
      this.sliderValue = sliderValue
    },
    handleSliderInput (event) {
      // Snap to the maximum if dragging close enough to the end
      if (Math.abs(this.sliderValue - this.max) < (this.step / 2)) {
        this.sliderValue = this.max
      } else {
        // Otherwise, snap to the closest step
        this.sliderValue = Math.round(this.sliderValue / this.step) * this.step
      }
      this.$emit('slider:change-value', this.sliderValue)
    },
    handleMouseUp (event) {
      this.$emit('slider:mouse-up')
    }
  }
}
</script>
