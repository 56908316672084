<template>
  <div class="card card--trend mb-2">
    <h3 class="t-overflow text-nowrap p-3 t-500" :title="parsedTrend.name">{{ parsedTrend.name }}</h3>
    <flex-chart
      :isLoaded="isLoaded"
      :graphData="parsedTrend.data || []"
      :name="'Trends'"
      :tooltipLabel="getTooltipLabel(parsedTrend.name)"
      chartType="line"
      color="#43AEC5"
      :yField="yField"
      :yLabel="yField"
      withFill
      isSmall
      hideYAxis
      :withTooltip="withTooltip"
    />
  </div>
</template>

<script>
import FlexChart from '@/components/shared/chart/FlexChart'

export default {
  name: 'trend',
  components: {
    FlexChart
  },
  props: {
    state: {
      type: Object,
      required: true
    },
    trend: {
      type: Object,
      required: true
    },
    withTooltip: {
      type: Boolean,
      required: false,
      default: true
    },
    tooltipSuffix: {
      type: String,
      required: false,
      default: 'mention'
    },
    showNormalizedCount: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      yField: this.showNormalizedCount ? 'normalisedCount' : 'count',
      isLoaded: false,
      parsedTrend: {}
    }
  },
  mounted () {
    this.$nextTick(() => this.parseData())
  },
  watch: {
    trend (newVal, oldVal) {
      if (newVal !== oldVal && newVal) this.parseData()
    },
    showNormalizedCount (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.yField = this.showNormalizedCount ? 'normalisedCount' : 'count'
        this.parseData()
      }
    }
  },
  methods: {
    getTooltipLabel (trendName) {
      let countInfo
      if (this.showNormalizedCount) {
        countInfo = `[bold]{normalisedCount}% [/] of`
      } else {
        countInfo = `[bold]{count} [/]`
      }

      let tooltipSuffix = this.tooltipSuffix
      tooltipSuffix = tooltipSuffix.replace('{trend}', trendName)
      return `${countInfo} ${this.getIndexAsString(this.state.index)} ${tooltipSuffix}`
    },
    parseData () {
      this.isLoaded = false
      this.parsedTrend = {
        ...this.trend,
        data: this.trend.data.sort((a, b) => b.year - a.year).map(d => {
          return {
            year: d.year.toString(),
            count: d.count,
            normalisedCount: d.total !== 0 ? Math.round((d.count / d.total) * 10000) / 100 : 0 // Trick to round numbers to 2 digits while preserving Number prototype
          }
        })
      }
      this.isLoaded = true
    }
  }
}
</script>
