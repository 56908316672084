<template>
  <div class="p-4 d-flex align-items-center" v-if="isFetchingConfig">
    <spinner />
  </div>
  <div class="p-4" v-else>
    <p class="t-xxxlr mb-3">
      Export <template v-if="scope === 'researchers'"> <b>{{ count < configData.limits.item ? count : configData.limits.item }}</b>{{ count > configData.limits.item ? `/${count}` : '' }} {{ getIndexAsString(scope) }}</template>
    </p>
    <template v-if="scope !== 'researchers' && scope !== 'news'">
      <p class="t-500 t-sm mb-1">Choose your export template:</p>
      <p>
        <radio-button :name="`exportType`"
          :value="'normal'"
          :valueText="`Export <b>${ count < configData.limits.item ? count : configData.limits.item }</b>${ count > configData.limits.item ? `/${count}` : '' } ${ getIndexAsString(scope) }`"
          :isActive="selectedExport === 'normal'"
          @input="handleRadioButton"
          class="mb-1"
        />

        <radio-button :name="`exportType`"
          :value="'contacts'"
          :valueText="`Export ${contactType} from <b>${ count < configData.limits.contact ? count : configData.limits.contact }</b>${ count > configData.limits.contact ? `/${count}` : '' } ${ getIndexAsString(scope) }`"
          :isActive="selectedExport === 'contacts'"
          @input="handleRadioButton"
        />
      </p>
    </template>
    <div class="t-sm">
      <p class="t-600 mb-0 mt-2">
        Download an Excel or CSV with the following properties:
      </p>

      <div class="row pl-3">
        <ul class="col col-12 col-md-4 mb-0" v-for="(fieldColumn, i) in fieldColumns" :key="`field-${i}`">
          <li v-for="field in fieldColumn" :key="field.name">
            {{ field.name }}
          </li>
        </ul>
      </div>
      <template v-if="!isEmpty(addons)">
        <p class="t-600 mb-0 mt-2">
          Add-on properties: <i class="fa fa-info-circle t-secondary ml-2" v-tooltip="'Add-on properties can be unlocked at an additional cost.<br /> To learn more, please book a call with our sales team.'" />
        </p>
        <div class="row pl-3">
          <ul class="col col-12 col-md-4 mb-0" v-for="(addonColumn, i) in addonColumns" :key="`addon-${i}`">
            <li v-for="addon in addonColumn" :key="addon.name" class="position-relative">
              <i v-if="addon.locked && $can('export')" class="fa fa-lock"
                style="position: absolute; left: -16px; top: 4px;"
              />
              {{ addon.name }}
            </li>
          </ul>
        </div>
      </template>

      <div class="bg-transparent-blue bd-radius p-2 mt-4">
        <i class="t-secondary fa fa-info-circle mr-1" /><a href="https://www.academiclabs.com/contact-sales" target="_blank" class="t-600">Book a call</a>
        {{ infoCopy }}
      </div>
    </div>

    <static-messages
      v-if="error"
      :messages="error"
      :variant="`danger`"
      @closed="error = null"
    />

    <div class="d-flex flex-column-reverse flex-md-row justify-content-end mt-3">
      <button
        class="btn bg-transparent no-border t-primary ml-3 mb-2"
        type="button"
        @click="close">Close
      </button>

      <a
        class="ml-3 mb-2 btn btn-primary--green-dark"
        href="https://www.academiclabs.com/contact-sales"
        target="_blank"
      >
        <i class="fa fa-info-circle mr-1" /> Book a call
      </a>
      <template v-for="type in EXPORT_TYPES">
        <button
          :key="type.name"
          class="btn btn-primary px-4 ml-3 mb-2"
          type="button"
          @click="exportFile(type)"
          :disabled="isLoading || !$can('export')"
        >
          <i class="fa fa-arrow-down mr-1" />
          <span v-if="isLoading && loadingType === type.endpoint" class="dots">Working</span>
          <span v-else>{{ `Download ${type.name} file` }}</span>
          <i class="fa fa-lock semi-transparent ml-2" v-if="!$can('export')" />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import Spinner from '@/components/shared/Spinner'
import RadioButton from '@/components/shared/form/RadioButton'

import moment from 'moment'
import SearchService from '@/services/search'

export default {
  name: 'exportModal',
  components: {
    StaticMessages,
    Spinner,
    RadioButton
  },
  data () {
    return {
      isFetchingConfig: true,
      configData: {},
      isLoading: false,
      loadingType: '',
      error: '',
      selectedExport: 'normal'
    }
  },
  created () {
    this.EXPORT_TYPES = [
      { extension: 'csv', name: 'CSV', endpoint: 'csv' },
      { extension: 'xlsx', name: 'Excel', endpoint: 'excel' }
    ]
  },
  props: {
    scope: {
      type: String,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  mounted () {
    const isWithSuggestions = !this.state.global && !this.$route.name.includes('bookmark_folders')
    SearchService.getExportConfig(this.scope, isWithSuggestions).then(response => {
      this.configData = response
      this.isFetchingConfig = false
    })
  },
  computed: {
    contactType () {
      // return 'leadership'
      switch (this.scope) {
        case 'projects':
          return 'participants'
        case 'publications':
          return 'authors'
        case 'patents':
          return 'inventors'
        case 'clinical_trials':
          return 'investigators'
        default:
          return 'leadership'
      }
    },
    infoCopy () {
      if (!this.configData.premium) return 'to enable export, or if you have any other questions.'
      return `to ${!this.configData.export_emails ? 'enable mail export, ' : ''} raise export limits, or if you have any other questions.`
    },
    isContactsExport () {
      return this.selectedExport === 'contacts'
    },
    fields () {
      return this.configData[this.isContactsExport ? 'contact_columns' : 'columns']
        .filter(c => !c.add_on)
        .sort((a, b) => a.order - b.order)
    },
    fieldColumns () {
      const columns = []
      const mid = Math.ceil(this.fields.length / 3)
      for (let col = 0; col < 3; col++) {
        columns.push(this.fields.slice(col * mid, col * mid + mid))
      }
      return columns
    },
    addons () {
      return this.configData[this.isContactsExport ? 'contact_columns' : 'columns']
        .filter(c => c.add_on)
        .sort((a, b) => a.order - b.order)
    },
    addonColumns () {
      const columns = []
      const mid = Math.ceil(this.addons.length / 3)
      for (let col = 0; col < 3; col++) {
        columns.push(this.addons.slice(col * mid, col * mid + mid))
      }
      return columns
    }
  },
  methods: {
    handleRadioButton (checked, value) {
      this.selectedExport = value
    },
    close () {
      this.$events.$emit('modal:close')
    },
    exportFile (exportType) {
      if (this.isLoading) return

      this.error = ''
      this.isLoading = true
      this.loadingType = exportType.endpoint

      SearchService.exportFile({
        ...this.state,
        index: this.scope,
        url: this.$route.fullPath
      }, exportType.endpoint, this.isContactsExport).then(response => {
        const humanReadableIndex = this.getIndexAsString(this.scope)
        this.isLoading = false
        this.loadingType = ''
        const fileName = `${humanReadableIndex}-${moment().format('YYYY-MM-DD')}.${exportType.extension}`
        // Generate blob from response and virtual link that will be 'clicked' to download the export-file
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        link.click()

        const maximumCount = this.isContactsExport ? this.configData.limits.contact : this.configData.limits.item

        this.$flash(
          {
            message: `<b>${this.count > maximumCount ? maximumCount : this.count} ${humanReadableIndex}</b> were exported as a ${exportType.name} file to your Downloads folder.`,
            cta: {
              icon: 'fa-info-circle',
              text: 'More info',
              action: () => {
                window.open('https://www.academiclabs.com/exportingdata', '_blank')
              }
            }
          },
          'success')

        this.close()
      }).catch(() => {
        this.isLoading = false
        this.error = 'Something went wrong, please try again.'
      })
    }
  }
}
</script>
