<template>
  <div class="long-readable-text">
    <div v-if="!isClicked" class="mb-0" :class="defaultClass">
      <slot v-if="this.$slots.default"></slot>
      <div v-else v-html="getText"></div>
    </div>
    <a class="d-block mt-0" href="#" v-if="!isClicked && checkLength(text, truncateAt) && isReadMore" @click.prevent="isClicked = !isClicked">
      Read more
    </a>

    <div v-if="isClicked" v-html="text" class="mb-0 is-rich-text" :class="{
      'html-pre-wrap': withPreWrap
    }"></div>
    <a v-if="isClicked" class="d-block mt-2" href="#" @click.prevent="isClicked = !isClicked">
      Read less
    </a>
  </div>
</template>

<script>
export default {
  name: 'longText',
  props: {
    truncateAt: {
      type: Number,
      default: 450
    },
    text: {
      type: String,
      required: false
    },
    isRichText: {
      type: Boolean,
      default: false
    },
    isReadMore: {
      type: Boolean,
      default: true
    },
    withPreWrap: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data () {
    return {
      isClicked: false
    }
  },
  computed: {
    defaultClass () {
      let isRichText = this.isRichText ? 'is-rich-text' : ''
      return `${this.getTruncated(this.text, this.truncateAt)} ${isRichText}`
    },
    getText () {
      return this.truncateAt !== -1 ? this.$options.filters.truncateKeepImage(this.text, this.truncateAt) : this.text
    }
  }
}
</script>
