import Pusher from 'pusher-js'
import ls from '@/plugins/localstorage'
import { hasMatchingSlug } from '@/plugins/utils'

export default {
  install (Vue, options) {
    let pusher = ''
    let cachedUser = null

    // Check whether or not tab is in foreground to activate pusher
    document.addEventListener('visibilitychange', (e) => {
      if (document.visibilityState === 'visible') {
        if (cachedUser) Vue.prototype.$pusher.initialize(cachedUser)
      } else {
        Vue.prototype.$pusher.disconnect(false)
      }
    })

    Vue.prototype.$pusher = {
      initialize (user) {
        cachedUser = user
        if (process.env.VUE_APP_DEPLOY_ENV === 'dev') Pusher.logToConsole = true

        pusher = new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
          cluster: 'eu',
          encrypted: true,
          authEndpoint: `${process.env.VUE_APP_API_URL}/authenticate/pusher`,
          auth: {
            headers: {
              'Authorization': ls.getToken()
            }
          }
        })

        let pusherUser = pusher.subscribe(`private-user-${user.user_id}`)
        let pusherAnnouncements = pusher.subscribe('announcements')

        this.researchGroupEvents(pusherUser)
        this.bookmarkEvents(pusherUser)
        this.communityEvents(pusherUser)
        this.communityResearchGroupEvents(pusherUser)
        this.organisationEvents(pusherUser)
        this.progressEvents(pusherUser)

        this.announcementEvents(pusherAnnouncements)
      },
      disconnect (clearCache = true) {
        if (pusher) pusher.disconnect()
        if (clearCache) cachedUser = null
      },
      progressEvents (pusherUser) {
        pusherUser.bind('progress-update', (data) => {
          Vue.prototype.$events.$emit('pusher:progress-update', data.uuid, data.progress)
        })
      },
      bookmarkEvents (pusherUser) {
        // Users that are invited to a bookmark_folder
        pusherUser.bind('folder-user-invite', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:user-invited')
        })

        // User that has received the invite
        pusherUser.bind('folder-user-invite-cancelled', (data) => {
          this.sendFlash(data)
        })

        // Owners & Admins from the bookmark_folder
        pusherUser.bind('folder-user-invite-confirmed', (data) => {
          this.sendFlash(data)
        })

        // Owners & Admins from the bookmark_folder
        pusherUser.bind('folder-user-invite-declined', (data) => {
          this.sendFlash(data)
        })

        // User where role has been changed
        pusherUser.bind('folder-role-changed', (data) => {
          this.sendFlash(data)
        })

        // Owners & Admins from the bookmark_folder
        pusherUser.bind('folder-user-left', (data) => {
          this.sendFlash(data)
        })

        // Owners & Admins from bookmark_folder
        pusherUser.bind('folder-deleted', (data) => {
          this.sendFlash(data)
        })

        // Contributors: Someone added a bookmark to a bookmark_folder
        pusherUser.bind('folder-bookmark-added', (data) => {
          this.sendFlash(data)
        })

        // Contributors: Someone removed a bookmark from a bookmark_folder
        pusherUser.bind('folder-bookmark-removed', (data) => {
          this.sendFlash(data)
        })
      },
      communityResearchGroupEvents (pusherUser) {
        // Research group that is invited to a community
        pusherUser.bind('community-research_group-invite', (data) => {
          this.sendFlash(data)

          // remove auto accepts
          if (data.pending) Vue.prototype.$events.$emit('pusher:community-research_group-invited')
        })

        // Research group that is invited to a community but cancelled
        pusherUser.bind('community-research_group-invite-cancelled', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-research_group-cancelled')
        })

        // Research group that has confirmed the invite to the community
        pusherUser.bind('community-research_group-invite-confirmed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-research_group-confirmed')
        })

        // Research group that has declined the invite to the community
        pusherUser.bind('community-research_group-invite-declined', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-research_group-declined')
        })

        // Research grouop has been removed from the community
        pusherUser.bind('community-research_group-removed', (data) => {
          this.sendFlash(data)
        })

        // Research group has left the community
        pusherUser.bind('community-research_group-left', (data) => {
          this.sendFlash(data)
        })

        // Research group has left the community
        pusherUser.bind('community-research_group-user-added', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('current_user:update')
        })
      },
      communityQAEvents (pusherUser) {
        pusherUser.bind('community-question-commented', (data) => {

        })

        pusherUser.bind('community-question-answered', (data) => {

        })

        pusherUser.bind('community-question-upvoted', (data) => {

        })

        pusherUser.bind('community-question-downvoted', (data) => {

        })

        pusherUser.bind('community-question-unvoted', (data) => {

        })

        pusherUser.bind('community-answer-commented', (data) => {

        })

        pusherUser.bind('community-answer-upvoted', (data) => {

        })

        pusherUser.bind('community-answer-downvoted', (data) => {

        })

        pusherUser.bind('community-answer-unvoted', (data) => {

        })
      },
      communityEvents (pusherUser) {
        // Users that are invited by a community
        pusherUser.bind('community-user-invite', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:user-invited')
        })

        // User that has received the invite
        pusherUser.bind('community-user-invite-cancelled', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:user-cancelled')
        })

        // Owners & Admins from the community
        pusherUser.bind('community-user-invite-confirmed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-user-confirmed')
        })

        // Someone joined the community
        pusherUser.bind('community-user-joined', (data) => {
          this.sendFlash(data)
        })

        // Owners & Admins from the community
        pusherUser.bind('community-user-invite-declined', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-user-declined')
        })

        // User has send a invite request
        pusherUser.bind('community-user-invite_request', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-user-invite_request')
        })

        // Users has canceled his invite request
        pusherUser.bind('community-user-invite_request-cancelled', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-user-invite_request-cancelled')
        })

        // Research group owner has canceled his invite request
        pusherUser.bind('community-user-invite_request-confirmed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('current_user:update')
          Vue.prototype.$events.$emit('pusher:user-invite_request-confirmed')
        })

        // Research group owner has canceled his invite request
        pusherUser.bind('community-user-invite_request-declined', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:user-invite_request-declined')
        })

        // User where role has been changed
        pusherUser.bind('community-role-changed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-role-changed')
          Vue.prototype.$events.$emit('community:updated', 'rw_access', data.rw_access)
        })

        // User where role has been changed
        pusherUser.bind('community-contact-changed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-contact-changed')
        })

        // Owners & Admins & Members from the community
        pusherUser.bind('community-name-changed', (data) => {
          let index = Vue.prototype.$currentUser.communities.findIndex((i) => hasMatchingSlug(i.slug_values, data.slug_values))

          Vue.prototype.$currentUser.communities[index].name = data.name
          Vue.prototype.$auth.setCurrentUser(Vue.prototype.$currentUser)

          Vue.prototype.$events.$emit('community:updated', 'name', data.name)
        })

        // User that has been removed from the community
        pusherUser.bind('community-user-removed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-user-removed')
          Vue.prototype.$events.$emit('current_user:update')
        })

        // Owners & Admins from the community
        pusherUser.bind('community-user-left', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:community-user-left')
          Vue.prototype.$events.$emit('pusher:community-user-declined')
        })

        // Owners & Admins & Members from the community
        pusherUser.bind('community-deleted', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('current_user:update')
        })
      },
      researchGroupEvents (pusherUser) {
        // User has send a invite request
        pusherUser.bind('research_group-user-invite_request', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:research_group-user-invite_request')
        })

        // Users has canceled his invite request
        pusherUser.bind('research_group-user-invite_request-cancelled', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:research_group-user-invite_request-cancelled')
        })

        // Research group owner has canceled his invite request
        pusherUser.bind('research_group-user-invite_request-confirmed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('current_user:update')
          Vue.prototype.$events.$emit('pusher:user-invite_request-confirmed', data)
        })

        // Research group owner has canceled his invite request
        pusherUser.bind('research_group-user-invite_request-declined', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:user-invite_request-declined')
        })

        // Users that are invited by a research group
        pusherUser.bind('research_group-user-invite', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:user-invited')
        })

        // User that has received the invite
        pusherUser.bind('research_group-user-invite-cancelled', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:user-cancelled')
        })

        // Owners & Admins from the research group
        pusherUser.bind('research_group-user-invite-confirmed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:research_group-user-confirmed')
        })

        // Owners & Admins from the research group
        pusherUser.bind('research_group-user-invite-declined', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:research_group-user-declined')
        })

        // User where role has been changed
        pusherUser.bind('research_group-role-changed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:research_group-role-changed')
          Vue.prototype.$events.$emit('research_group:updated', 'rw_access', data.rw_access)
        })

        // User where role has been changed
        pusherUser.bind('research_group-contact-changed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:research_group-contact-changed')
        })

        // Owners & Admins & Members from the research group
        pusherUser.bind('research_group-name-changed', (data) => {
          const index = Vue.prototype.$currentUser.research_groups.findIndex((i) => hasMatchingSlug(i.slug_values, data.slug_values))
          Vue.prototype.$currentUser.research_groups[index].name = data.name
          Vue.prototype.$auth.setCurrentUser(Vue.prototype.$currentUser)

          // TODO fix with highlights (v-html)
          Vue.prototype.$events.$emit('research_group:updated', 'name', data.name)
        })

        // User that has been removed from the invite
        pusherUser.bind('research_group-user-removed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:research_group-user-removed')
          Vue.prototype.$events.$emit('current_user:update')
        })

        // Owners & Admins from the research group
        pusherUser.bind('research_group-user-left', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('pusher:research_group-user-left')
          Vue.prototype.$events.$emit('pusher:research_group-user-declined')
        })

        // Owners & Admins & Members from the research group
        pusherUser.bind('research_group-deleted', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('current_user:update')
        })
      },
      organisationEvents (pusherUser) {
        pusherUser.bind('organisation-verified', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('current_user:update')
        })

        pusherUser.bind('organisation-declined', (data) => {
          this.sendFlash(data)
        })

        pusherUser.bind('organisation-user-invite_request-confirmed', (data) => {
          this.sendFlash(data)
          Vue.prototype.$events.$emit('current_user:update')
        })

        pusherUser.bind('organisation_request', (data) => {
          this.sendFlash(data)

          let acData = ls.getAccountData()
          data.organisationRequestClosed = false
          ls.setAccountData(acData)

          Vue.prototype.$events.$emit('user:updated', 'pending_organisation_requests_count', 1)
        })

        pusherUser.bind('organisation-user-invite_request-declined', (data) => {
          this.sendFlash(data)
        })
      },
      announcementEvents (pusherAnnouncements) {
        pusherAnnouncements.bind('announcements-new', (data) => {
          Vue.prototype.$events.$emit('pusher:announcements-new', data.announcement)
        })
      },
      sendFlash (data) {
        Vue.prototype.$flash(this.getMessage(data), 'success', data)
      },
      getMessage (data) {
        return `<span class="t-600">${data.message_prefix}</span> ${data.message} <span class="t-600">${data.message_suffix}</span>`
      }
    }
  }
}
