<template>
  <button type="button" @click.prevent="clickButton"
    class="btn width--100per t-600 mb-2 box-shadow"
    :class="{
      'btn-secondary': isFollowing || isSecondary,
      'btn-primary': !isFollowing && !isSecondary
    }"
    v-tooltip="tooltip"
  >
    <template v-if="isLoading">
      <i class="fa fa-spinner fa-spin" />
    </template>
    <template v-else>
      <template v-if="isFollowing">
        <i class="fa fa-bell-slash t-xs pr-2" />Alert Created
      </template>
      <template v-else>
        <i class="fa fa-bell t-xs pr-2" />{{ buttonText }}
      </template>
    </template>
  </button>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'profileCreateAlert',
  props: {
    scope: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isSecondary: {
      type: Boolean,
      default: false
    },
    customTooltip: {
      type: String,
      required: false,
      default: ''
    },
    customText: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      hasError: false,
      isFollowing: false
    }
  },
  mounted () {
    if (this.item.subscribed) this.isFollowing = true
  },
  computed: {
    followType () {
      switch (this.scope) {
        case 'researchers':
          return 'researcher'
        case 'organisations':
          return this.getMostRelevantOrganisationCategory(this.item.organisation_types)
        case 'research_groups':
        default:
          return 'group'
      }
    },
    tooltip () {
      if (this.isPublicView) return 'Other users can click this button to create an alert for this profile and get notified of updates'
      if (this.customTooltip) return this.customTooltip
      if (this.isFollowing) {
        return `You are following this ${this.followType} and will receive an email when this profile is updated. Click to remove this alert.`
      } else {
        return `Create an alert to follow this ${this.followType} and receive an email when this profile is updated.`
      }
    },
    buttonText () {
      if (this.customText) return this.customText
      return 'Create Alert'
    },
    isPublicView () {
      return this.$can.getAsPublic()
    }
  },
  methods: {
    ...mapActions('alerts', [
      'createAlert',
      'deleteAlert',
      'resetAlerts'
    ]),
    clickButton () {
      if (this.isLoading || this.isPublicView) return
      if (this.isFollowing) {
        this.unfollow()
      } else {
        this.follow()
      }
    },
    follow () {
      this.isLoading = true
      this.createAlert({
        alert: this.item,
        type: this.scope
      }).then(() => {
        this.isLoading = false
        this.isFollowing = true
        this.resetAlerts()
      }).catch(e => {
        this.handleError(e)
      })
    },
    unfollow () {
      this.isLoading = true
      this.deleteAlert({
        id: this.item.slug_values[0],
        type: this.scope
      }).then(() => {
        this.isLoading = false
        this.isFollowing = false
      }).catch(e => {
        this.handleError(e)
      })
    },
    handleError (error) {
      if (error.status === 400 && error.data && error.data.errors) {
        this.isFollowing = true
      } else {
        // TODO: Actually show an error, but shouldn't happen
        this.hasError = true
      }
      this.isLoading = false
    }
  }
}
</script>
