<template>
  <base-modal :isLoaded="isLoaded"
    :isError404="isError404"
    :scope="scope"
    :data="data"
    :error="error"
    @clearError="error = null">
      <template #modal-content>
        <div class="d-flex justify-content-between align-items-baseline mb-3">
          <h2 class="t-secondary t-xlr">{{ scope | capitalize | humanize }}</h2>
          <div class="d-flex">
            <share
              :scope="scope"
              :itemName="data.full_title"
              inModal
            />
            <bookmark-folder
              v-if="!isEmpty(data) && !isEmpty($currentUser)"
              :currentItem="data"
              :scope="getScope"
              isButton
            />
          </div>
        </div>
        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
        <h1 class="mb-3 t-700 t-xxlr mb-4" v-html="data.highlights.full_title"></h1>

        <p v-if="data.official_title" class="mb-4">
          <span class="t-600">Official Title: </span>{{ data.official_title }}
        </p>

        <p v-if="data.highlights.brief_summary" class="mb-4">
          <span class="t-600">Brief Summary: </span><span v-html="data.highlights.brief_summary"></span>
        </p>

        <!-- phases -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">{{'Phase' | pluralize(data.phases && data.phases.length)}}:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="!isEmpty(data.phases)">{{data.phases | separateByComma('phase')}}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- start date -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Start Date:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="data.start_date">{{ data.start_date | formatDate('MMMM D, YYYY') }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- primary completion date -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Primary Completion Date:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="data.primary_completion_date">{{ data.primary_completion_date | formatDate('MMMM D, YYYY') }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- study completion date -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Study Completion Date:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="data.completion_date">{{ data.completion_date | formatDate('MMMM D, YYYY') }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- status -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Status:</div>
          <div class="col-6 col-md-7 col-xl-8 capitalize">{{data.status ? data.status : '-'}}</div>
        </div>
        <!-- results url -->
        <div class="row" v-if="resultsUrl">
          <div class="col-6 col-md-5 col-xl-4 t-600">Results:</div>
          <div class="col-6 col-md-7 col-xl-8"><a :href="resultsUrl | formatWebsite" target="_blank">{{ resultsUrl }}</a></div>
        </div>
        <!-- why_stoped -->
        <div class="row" v-if="data.why_stopped">
          <div class="col-6 col-md-5 col-xl-4 t-600">Why Stopped:</div>
          <div class="col-6 col-md-7 col-xl-8 capitalize">{{ data.why_stopped }}</div>
        </div>
        <!-- enrollment size -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Enrollment Size:</div>
          <div class="col-6 col-md-7 col-xl-8">{{data.enrollment_count ? data.enrollment_count : '-'}}</div>
        </div>
        <!-- expanded access status -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Expanded Access Status:</div>
          <div class="col-6 col-md-7 col-xl-8">{{data.expanded_access_status ? data.expanded_access_status : '-'}}</div>
        </div>
        <!-- conditions/diseases -->
        <div class="row mb-2">
          <div class="col-6 col-md-5 col-xl-4 t-600">Conditions/Diseases:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <keywords
              v-if="!isEmpty(data.diseases)"
              :keywords="data.diseases"
              :limit="5"
              property="term">
            </keywords>
            <span v-else>-</span>
          </div>
        </div>
        <!-- interventions -->
        <div class="row mb-2">
          <div class="col-6 col-md-5 col-xl-4 t-600">Interventions:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <keywords
              v-if="!isEmpty(data.interventions)"
              :keywords="data.interventions"
              :limit="5"
              property="name"
              labelProperty="type"
              tooltipProperty='description'>
            </keywords>
            <span v-else>-</span>
          </div>
        </div>
        <!-- diseases MeSH -->
        <div class="row mb-2">
          <div class="col-6 col-md-5 col-xl-4 t-600">Diseases MeSH:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <label-list
              v-if="!isEmpty(data.mesh_descriptors)"
              :title="'MeSH Terms'"
              :filterKey="'diseases_mesh'"
              :externalUrlLabel="'Open in NIH MeSH database'"
              :labels="data.mesh_descriptors"
              :renderTitle="false"
            />
            <span v-else>-</span>
          </div>
        </div>
        <!-- drugs MeSH -->
        <div class="row mb-2">
          <div class="col-6 col-md-5 col-xl-4 t-600">Drugs MeSH:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <label-list
              v-if="!isEmpty(data.mesh_intervention_descriptors)"
              :title="'MeSH Terms'"
              :filterKey="'drugs_mesh'"
              :externalUrlLabel="'Open in NIH MeSH database'"
              :labels="data.mesh_intervention_descriptors"
              :renderTitle="false"
            />
            <span v-else>-</span>
          </div>
        </div>

        <!-- DETAILED DESCRIPTION -->
        <long-text-collapsible
          v-if="data.highlights.detailed_description"
          title="Detailed Description"
          :truncateAt="-1"
          :isReadMore="false"
          :text="data.highlights.detailed_description"
          :collapsed="true"
          class="mb-4 html-pre-wrap"
        />

        <!-- STUDY DESIGN - ARMS AND INTERVENTIONS -->
        <long-text-collapsible
          title="Study Design - Arms and Interventions"
          :truncateAt="-1"
          :isReadMore="false"
          class="mb-4 html-pre-wrap"
          :collapsed="true"
        >
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Intervention Model:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.intervention_model_assignment ? data.intervention_model_assignment : '-'}}</div>
          </div>
          <div class="row mb-4">
            <div class="col-6 col-md-5 col-xl-4 t-600">Intervention Model Description:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.interventional_model_description ? data.interventional_model_description : '-'}}</div>
          </div>

          <div v-for="(intervention_arm, i) in data.intervention_arms" :key="`${intervention_arm}-${i}`" class="mb-4">
            <div class="row mb-2">
              <div class="col-12 mb-2 t-600">Participant Group/Arm {{ i + 1 }} - <span v-if="intervention_arm.arm_type">{{ intervention_arm.arm_type }}: </span>{{ intervention_arm.arm_title }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-6 col-md-5 col-xl-4 t-600">Description:</div>
              <div class="col-6 col-md-7 col-xl-8 capitalize">{{intervention_arm.arm_description ? intervention_arm.arm_description : '-'}}</div>
            </div>
            <div class="row">
              <div class="col-6 col-md-5 col-xl-4 t-600">Other Names:</div>
              <div class="col-6 col-md-7 col-xl-8 capitalize">{{ !isEmpty(intervention_arm.arm_intervention_names) ? intervention_arm.arm_intervention_names.join(', ') : '-' }}</div>
            </div>
          </div>
        </long-text-collapsible>

        <!-- STUDY OUTCOME MEASURES -->
        <long-text-collapsible
          v-if="hasStudyOutcomeMeasures"
          title="Study Outcome Measures"
          :truncateAt="-1"
          :isReadMore="false"
          :collapsed="true"
          class="mb-4 html-pre-wrap"
        >
          <long-text-collapsible
            v-if="data.highlights.primary_objective"
            title="Primary objective"
            :truncateAt="-1"
            :isReadMore="false"
            :text="data.highlights.primary_objective"
            class="html-pre-wrap content-collapsible--parent mb-2"
            :fontSizeEditable="false"
            :collapsed="false"
          />

          <long-text-collapsible
            v-if="data.highlights.secondary_objective"
            title="Secondary objective"
            :truncateAt="-1"
            :isReadMore="false"
            :text="data.highlights.secondary_objective"
            class="html-pre-wrap content-collapsible--parent mb-2"
            :fontSizeEditable="false"
            :collapsed="true"
          />

          <long-text-collapsible
            v-if="data.highlights.other_objective"
            title="Other objective"
            :truncateAt="-1"
            :isReadMore="false"
            :text="data.highlights.other_objective"
            class="html-pre-wrap content-collapsible--parent"
            :fontSizeEditable="false"
            :collapsed="true"
          />
        </long-text-collapsible>

        <!-- STUDY DESIGN - DETAILS -->
        <long-text-collapsible
          title="Study Design - Details"
          :truncateAt="-1"
          :isReadMore="false"
          class="mb-4 html-pre-wrap"
          :collapsed="true"
        >
          <!-- study_type -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Study Type:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.study_type ? data.study_type : '-'}}</div>
          </div>
          <!-- observational_model -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Observational Model:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.observational_model ? data.observational_model : '-'}}</div>
          </div>
          <!-- primary_purpose -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Primary Purpose:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.primary_purpose ? data.primary_purpose : '-'}}</div>
          </div>
          <!-- time_perspective -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Time Perspective:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.time_perspective ? data.time_perspective : '-'}}</div>
          </div>
          <!-- target_duration -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Target Duration:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.target_duration ? data.target_duration : '-'}}</div>
          </div>
          <!-- allocation -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Allocation:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.design_allocation ? data.design_allocation : '-'}}</div>
          </div>
          <!-- masking -->
          <div class="row" v-if="data.mask_info">
            <div class="col-6 col-md-5 col-xl-4 t-600">Masking:</div>
            <div class="col-6 col-md-7 col-xl-8">{{ data.mask_info.masking }} <span v-if="!isEmpty(data.mask_info.who_masked)">({{ data.mask_info.who_masked.join(', ') }})</span></div>
            <div class="col-6 col-md-5 col-xl-4 t-600">Masking Description:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.mask_info.masking_description ? data.mask_info.masking_description : '-'}}</div>
          </div>
          <!-- bio_specimen.retention -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Biospecimen Retention:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.bio_specimen && data.bio_specimen.retention ? data.bio_specimen.retention : '-'}}</div>
          </div>
          <!-- bio_specimen.description -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Biospecimen Description:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.bio_specimen && data.bio_specimen.description ? data.bio_specimen.description : '-'}}</div>
          </div>
        </long-text-collapsible>

        <!-- ELIGIBILITY CRITERIA -->
        <long-text-collapsible
          title="Eligibility Criteria"
          :truncateAt="-1"
          :isReadMore="false"
          class="mb-4 html-pre-wrap"
          :collapsed="true"
        >
          <!-- age group -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Age Group:</div>
            <div class="col-6 col-md-7 col-xl-8">
              <span v-if="!isEmpty(data.population_ages)" class="capitalize">{{ data.population_ages | separateByComma('population_age')}}</span>
              <span v-else>-</span>
            </div>
          </div>
          <!-- min age -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Min Age:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.minimum_age ? data.minimum_age : '-'}}</div>
          </div>
          <!-- max age -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Max Age:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.maximum_age ? data.maximum_age : '-'}}</div>
          </div>
          <!-- Gender -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">{{'Gender' | pluralize(data.genders && data.genders.length)}}:</div>
            <div class="col-6 col-md-7 col-xl-8">
              <span v-if="!isEmpty(data.genders)">{{data.genders | separateByComma('name')}}</span>
              <span v-else>-</span>
            </div>
          </div>
          <!-- healthy volunteers -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Accepts Healthy Volunteers:</div>
            <div class="col-6 col-md-7 col-xl-8">{{ data.healthy_volunteers }}</div>
          </div>
          <!-- sampling method -->
          <div class="row mb-2">
            <div class="col-6 col-md-5 col-xl-4 t-600">Sampling Method:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.sampling_method ? data.sampling_method : '-'}}</div>
          </div>
          <!-- eligibility criteria -->
          <long-text-collapsible v-if="data.highlights.eligibility_criteria"
            title="Inclusion and Exclusion criteria"
            :truncateAt="-1"
            :isReadMore="false"
            :text="data.highlights.eligibility_criteria"
            class="mb-4 html-pre-wrap content-collapsible--parent"
            :fontSizeEditable="false"
            />
        </long-text-collapsible>

        <!-- LOCATIONS -->
        <long-text-collapsible
          v-if="!isEmpty(locations)"
          :title="locationsTitle"
          :truncateAt="-1"
          :isReadMore="false"
          class="mb-4 html-pre-wrap"
          :collapsed="true"
        >
         <ul>
            <li v-for="(location, index) in locations" :key="index">
              <template v-if="location.organisation && !isEmpty(location.organisation.slug_values)">
                <router-link
                  class="capitalize"
                  :to="{ name: 'organisation.show', params: { id: location.organisation.slug_values[0] }}"
                  v-html="location.organisation.name"
                />,
              </template>
              <span class="capitalize" v-else-if="location.organisation && location.organisation.name">{{ location.organisation.name }}, </span>
              <span v-html="renderLocationAsString(location)" />
              <flag :title="renderCountryFlagTooltip(location.country)"
                v-if="location.country && location.country.alpha_2"
                :iso="location.country.alpha_2 | lowercase"
                :squared="false"
                class="bd-radius ml-1 t-xs" />
            </li>
          </ul>
        </long-text-collapsible>

        <!-- ASSOCIATED PUBLICATIONS -->
        <long-text-collapsible
          v-if="!isEmpty(data.citations)"
          title="Associated Publications"
          :truncateAt="-1"
          :isReadMore="false"
          class="mb-4 html-pre-wrap"
          :collapsed="true">
          <ul>
            <li v-for="(citation, index) in visibleCitations" :key="`citation-${index}`">
              <a
                v-if="citation.pmid"
                :href="`https://app.academiclabs.com/search/publications?keyword=${citation.pmid}&search_by_fields=Publication%20Identifier%20%28DOI%2FPMID%29`"
                target="_blank">
                <span v-if="citation.citation_type">({{ citation.citation_type }}) </span>{{ citation.value }}
              </a>
              <span v-else>{{ citation.value }}</span>
            </li>
          </ul>
          <a
            v-if="data.citations.length > maxVisibleCitations"
            @click.prevent="showAllCitations = !showAllCitations"
            class="t-sm ml-1" href="#"
          >{{ showAllCitations ? "Show Less" : "Show All" }}</a>
        </long-text-collapsible>

        <!-- ADDITIONAL INFORMATION -->
        <long-text-collapsible
          title="Additional Information"
          :truncateAt="-1"
          :isReadMore="false"
          class="mb-4 html-pre-wrap"
          :collapsed="true"
        >
          <!-- trial id -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Trial ID:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.trial_id ? data.trial_id : '-'}}</div>
          </div>
           <!-- register -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Register:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.register ? data.register : '-'}}</div>
          </div>
          <!-- original record -->
          <div class="row mb-2">
            <div class="col-6 col-md-5 col-xl-4 t-600">Original Record:</div>
            <div class="col-6 col-md-7 col-xl-8">
              <span v-for="(url, index) in data.urls" :key="index">
                  <a :href="url | formatWebsite" target="_blank">{{ url }}</a><br/>
              </span>
            </div>
          </div>
           <!-- keywords -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Keywords:</div>
            <div class="col-6 col-md-7 col-xl-8">
              <keywords v-if="!isEmpty(data.highlights.keywords)"
                :limit="10"
                :keywords="data.highlights.keywords">
              </keywords>
              <span v-else>-</span>
            </div>
          </div>
        </long-text-collapsible>

        <!-- FDA OVERSIGHT -->
        <long-text-collapsible
          title="FDA Oversight"
          :truncateAt="-1"
          :isReadMore="false"
          class="mb-4 html-pre-wrap"
          :collapsed="true"
        >
          <!-- has data monitoring commitee -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Has Data Monitoring Committee:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.fda_oversight && data.fda_oversight.has_dmc ? booleanToYesNo(data.fda_oversight.has_dmc) : '-'}}</div>
          </div>
           <!-- has section 801 violation -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Has Section 801 Violation:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.fda_oversight && data.fda_oversight.is_section_801_violation ? booleanToYesNo(data.fda_oversight.is_section_801_violation) : '-'}}</div>
          </div>
          <!-- is fda regulated drug -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Is FDA Regulated Drug:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.fda_oversight && data.fda_oversight.is_fda_regulated_drug ? booleanToYesNo(data.fda_oversight.is_fda_regulated_drug) : '-'}}</div>
          </div>
          <!-- is fda regulated device -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Is FDA Regulated Device:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.fda_oversight && data.fda_oversight.is_fda_regulated_device ? booleanToYesNo(data.fda_oversight.is_fda_regulated_device) : '-'}}</div>
          </div>
          <!-- is unapproved device -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Is Uapproved Device:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.fda_oversight && data.fda_oversight.is_unapproved_device ? booleanToYesNo(data.fda_oversight.is_unapproved_device) : '-'}}</div>
          </div>
          <!-- has pediatric postmarket surveillance system -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Has Pediatric Postmarket Surveillance System:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.fda_oversight && data.fda_oversight.is_ppsd ? booleanToYesNo(data.fda_oversight.is_ppsd) : '-'}}</div>
          </div>
          <!-- product manufactured in and exported from the us -->
          <div class="row">
            <div class="col-6 col-md-5 col-xl-4 t-600">Product Manufactured in and Exported from the U.S.:</div>
            <div class="col-6 col-md-7 col-xl-8">{{data.fda_oversight && data.fda_oversight.is_us_export ? booleanToYesNo(data.fda_oversight.is_us_export) : '-'}}</div>
          </div>
        </long-text-collapsible>
      </template>

      <template #modal-sidebar>
        <organisation-summary v-if="!isEmpty(data.sponsors)"
          :organisations="data.sponsors"
          :title="'Sponsors'"
          class="mt-3">
        </organisation-summary>

        <organisation-summary v-if="!isEmpty(data.highlights.collaborators)"
          :organisations="data.highlights.collaborators"
          :title="'Collaborators'"
          class="mt-3">
        </organisation-summary>

        <researcher-summary v-if="!isEmpty(data.highlights.investigators)"
          :researchers="data.highlights.investigators"
          :title="'Investigators'"
          class="mt-3">
        </researcher-summary>

        <researcher-summary v-if="!isEmpty(data.contacts)"
          :researchers="data.contacts"
          :title="'Contacts'"
          class="mt-3">
        </researcher-summary>

        <identifiers :item="data" />
      </template>
  </base-modal>
</template>

<script>
import ClinicalTrialService from '@/services/clinical_trial'
import sharedJsModal from '@/mixins/search/results/baseModal'

import BaseModal from '@/components/search/results/BaseModal'
import Keywords from '@/components/shared/Keywords'
import ResearcherSummary from '@/components/shared/ResearcherSummary'
import BookmarkFolder from '@/components/bookmark_folder/Show'
import LongTextCollapsible from '@/components/shared/LongTextCollapsible'
import OrganisationSummary from '@/components/shared/OrganisationSummary'
import Identifiers from '@/components/qa/Identifiers'
import Share from '@/components/shared/profile/Share'
import LabelList from '@/components/shared/label/LabelList'

export default {
  name: 'modalClinicalTrial',
  mixins: [sharedJsModal],
  components: {
    BaseModal,
    Keywords,
    ResearcherSummary,
    BookmarkFolder,
    LongTextCollapsible,
    OrganisationSummary,
    Identifiers,
    Share,
    LabelList
  },
  props: {
    slug: {
      type: String,
      default: ''
    },
    maxVisibleCitations: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      objectForHighlighting: {
        properties: ['full_title', 'medical_condition', 'primary_objective', 'secondary_objective', 'other_objective', 'eligibility_criteria', 'brief_summary', 'detailed_description'],
        arrays: ['keywords.keyword'],
        researchers: ['investigators.initial_name.normalized', 'investigators.name.normalized', 'collaborators.name.normalized']
      },
      showAllCitations: false
    }
  },
  created () {
    this.scope = 'clinical_trial'
  },
  computed: {
    locations () {
      if (!this.data.locations) return []

      // Check if locations array has 1 or more organisations
      const hasOrganisations = !!this.data.locations.find(location => {
        return location.organisations && !this.isEmpty(location.organisations)
      })

      // If there are no organisations, simply return an array with all countries
      if (!hasOrganisations) {
        return this.data.locations.filter(location => {
          return location.country
        })
      }

      // If there are organisations, map data to structure used in render
      const locations = this.data.locations.reduce((locationsArray, location) => {
        if (location.organisations) {
          location.organisations.forEach(organisation => {
            locationsArray.push({
              // use country form organisation, fall back to more generic country on 'location'
              country: organisation.country || location.country,
              organisation: organisation
            })
          })
        }

        return locationsArray
      }, [])
      return locations
    },
    locationsTitle () {
      return this.$options.filters.pluralize('Location', this.locations.length)
    },
    visibleCitations () {
      return this.showAllCitations ? this.data.citations : this.data.citations.slice(0, this.maxVisibleCitations)
    },
    resultsUrl () {
      if (this.data.has_results) {
        const clinicalTrialsUrl = this.data.urls.find(url =>
          url.includes('clinicaltrials.gov')
        )
        return clinicalTrialsUrl ? `${clinicalTrialsUrl}?tab=results` : null
      }

      return null
    },
    hasStudyOutcomeMeasures () {
      return (this.data.primary_objective || this.data.secondary_objective || this.data.other_objective)
    }
  },
  methods: {
    fetch (slug) {
      ClinicalTrialService.getByID(slug).then(clinicalTrial => {
        this.data = clinicalTrial
        this.fetchHighlights(this.$options.filters.pluralize(this.scope), slug)
      }).catch(err => {
        this.handleCustomError(err)
      })
    },
    renderLocationAsString (location) {
      const city = location.organisation && location.organisation.city
      const state = location.organisation && location.organisation.state

      const hasCityOrState = !!(city || state)
      let element = ''
      if (city) element += city
      if (state) element += ` ${state}`
      if (hasCityOrState) element += `, `
      if (location.country) element += location.country.name
      return element
    },
    booleanToYesNo (value) {
      return value ? 'Yes' : 'No'
    }
  }
}
</script>
