import Vue from 'vue'

const HIGHLIGHTS = '/highlights'
const SEARCH = '/search'
const TOP_MATCHES = '/search_top_matches'
const TERM_SUGGESTIONS = '/term_suggestions'
const GRAPH_DATA = '/graph_data'
const FILTERS = '/filters'
const CONFIG = '/filter_config'
const COUNTS = '/count'
const LIST = '/list'
const TYPEAHEAD = '/typeahead'
const AUTOCOMPLETE = '/autocomplete'
const BROWSE = '/browse'
const SYNONYMS = '/synonyms'
const FLASK_ENDPOINT = `${process.env.VUE_APP_FLASK_URL}/api`

export default {
  search (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}${SEARCH}`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  synonyms (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}${SYNONYMS}`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  filters (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}${FILTERS}`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  counts (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}${COUNTS}`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  getFilterSets (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}/filter_sets`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  searchTopMatches (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}${TOP_MATCHES}`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  termSuggestions (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}${TERM_SUGGESTIONS}`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  graphData (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}${GRAPH_DATA}`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  expertiseData (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}/expertises`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  networkGraphData (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}/collaborating_with`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  typeahead (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}${TYPEAHEAD}`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  list (q) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}${LIST}`, q).then(response => response.data)
  },
  config (q) {
    return Vue.prototype.$http.httpCancelRequest(`${process.env.VUE_APP_SEARCH_URL}${CONFIG}`, q).then(response => response.data)
  },
  autocomplete (q) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}${AUTOCOMPLETE}`, q).then(response => response.data)
  },
  browseCategories (q) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}${BROWSE}/categories`, q).then(response => response.data)
  },
  browseDetails (q) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}${BROWSE}/details`, q).then(response => response.data)
  },
  highlights (q) {
    // No point in fetching highlights if there is no keyword to highlight
    if (q && !q.keyword) return Promise.resolve({})
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}${HIGHLIGHTS}`, q).then(response => response.data)
  },
  highlightsDetailView (q) {
    // No point in fetching highlights if there is no keyword to highlight
    if (q && !q.keyword) return Promise.resolve({})
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}${HIGHLIGHTS}?view=detail`, q).then(response => response.data)
  },
  getExportConfig (index, isWithSuggestions = true) {
    return Vue.prototype.$http.get(`${FLASK_ENDPOINT}/export/${index}${isWithSuggestions ? '/scoped' : ''}`).then(response => response.data)
  },
  exportFile (q, fileType, exportContacts = false) {
    return Vue.prototype.$httpSkip500.post(`${FLASK_ENDPOINT}/export/${exportContacts ? 'contacts/' : ''}${fileType}`, q, {
      responseType: 'blob'
    }).then(response => response)
  },
  getMapData (q) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}/maps`, q).then(response => response.data)
  },
  getTrendsConfiguration (q) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}/trends_config`, q).then(response => response.data)
  },
  getTrendsData (q) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}/trends`, q).then(response => response.data)
  },
  getSearchableFields (index) {
    return Vue.prototype.$http.get(`${process.env.VUE_APP_SEARCH_URL}/searchable_fields?index=${index}`).then(response => response.data)
  },
  getDashboardConfiguration (index) {
    return Vue.prototype.$http.get(`${process.env.VUE_APP_SEARCH_URL}/dashboard_data?index=${index}`).then(response => response.data)
  },
  getDashboardData (q, cancelToken) {
    return Vue.prototype.$httpCancelRequest.post(`${process.env.VUE_APP_SEARCH_URL}/dashboard_data`, q, {
      cancelToken: cancelToken
    }).then(response => response.data)
  },
  getAiAnalysisOverview (q) {
    return Vue.prototype.$httpSilentError.post(`${FLASK_ENDPOINT}/ai_analysis/overview`, q).then(response => response.data)
  },
  getAiAnalysis (q) {
    return Vue.prototype.$httpSilentError.post(`${FLASK_ENDPOINT}/ai_analysis/analyze`, q).then(response => response.data)
  },
  getAiAnalysisPromptPerItem (q) {
    return Vue.prototype.$httpSkip500.post(`${FLASK_ENDPOINT}/ai_analysis/analyze_per_item`, q, {
      responseType: 'blob'
    }).then(response => response).catch(err => {
      // If there's an error, it's sent in the form of a JSON response, not a blob. Let's reconstruct it:
      return err.data.text().then(text => {
        const customError = {
          status: err.status,
          data: { error: JSON.parse(text).error || 'Unknown error' }
        }
        throw customError
      })
    })
  },
  getAiAnalysisPromptPerItemPreview (q) {
    return Vue.prototype.$httpSilentError.post(`${FLASK_ENDPOINT}/ai_analysis/analyze_per_item_preview`, q).then(response => response.data)
  }
}
