<template>
  <div>
    <p v-if="renderTitle" class="t-primary t-md t-400 mb-1">
      {{ title }}
      <span class="t-secondary">{{ labels.length }}</span>
    </p>
    <template v-if="noDropdown">
      <span v-for="(label, i) in labels" class="d-inline-block y-a-label mr-1 mb-1 t-sm px-2" :key="i" v-html="label" />
    </template>
    <template v-else>
      <label-item
        v-for="(label, i) in labels"
        :label="label"
        :key="i"
        :externalUrlLabel="externalUrlLabel"
        :title="title"
        :filterKey="filterKey" />
    </template>
  </div>
</template>

<script>
import LabelItem from '@/components/shared/label/LabelItem'

export default {
  name: 'labelList',
  props: {
    labels: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    externalUrlLabel: {
      type: String,
      required: false,
      default: ''
    },
    filterKey: {
      type: String,
      required: false,
      default: ''
    },
    noDropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    renderTitle: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    LabelItem
  }
}
</script>
